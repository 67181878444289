import React from "react";
import { ContainerStepper, Step } from "./styles";

const Stepper = ({ actualStepper, setActualStepper }) => {
	const ThreeSteps = [0, 1, 2];

	return (
		<ContainerStepper>
			{ThreeSteps.map((_, index) => {
				return (
					<Step
						isActual={actualStepper === index}
						onClick={() => setActualStepper(index)}
					></Step>
				);
			})}
		</ContainerStepper>
	);
};

export default Stepper;
