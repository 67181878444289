import React from "react";
import "./style.css";
import "./mobile.styles.css";
import { news, me } from "../../../axios";
import Page from "../components/Page";

import bolinha from "../../../assets/images/bolinha.svg";
import Simulator from "./Simulator";

export default class Home extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			user: { name: "" },
			loadingNews: false,
			futNews: [],
		};
		this.loadNews = this.loadNews.bind(this);
		this.goToWhatsAppSupport = this.goToWhatsAppSupport.bind(this);
	}

	loadNews() {
		this.setState({ ...this, loadingNews: true, futNews: [] });
		news().then((futNews) => {
			setTimeout(() => {
				this.setState({ ...this, loadingNews: false, futNews });
			}, 300);
		});
	}

	goToWhatsAppSupport() {
		window.location.href =
			"https://api.whatsapp.com/send/?phone=5511971074590&text=Ol%C3%A1%21+Estou+na+plataforma+da+Panzer+e+gostaria+de+tirar+algumas+d%C3%BAvidas...+Pode+me+ajudar%3F&type=phone_number&app_absent=0";
	}

	async getUserData() {
		let userData = window.localStorage.getItem(
			process.env.REACT_APP_SITE_STORAGE_KEY_USER_DATA
		);
		if (userData) {
			userData = JSON.parse(userData);
			this.setState({ ...this, user: userData });
			return;
		} else {
			me()
				.then((user) => this.setState({ ...this, user }))
				.catch();
		}
	}

	async setUpData() {
		await this.getUserData();
		this.loadNews();
	}

	componentDidMount() {
		this.setUpData();
	}
	render() {
		return (
			<React.Fragment>
				<Page title={`Bem vindo ${this.state.user.name}`}>
					<div className="row">
						<div
							className="d-flex gap-5 big__section"
							style={{ justifyContent: "space-between" }}
						>
							<div className="home-first-section">
								<div className="news">
									<div className="news-header">
										<p>Notícias</p>
									</div>
									<ul className="news-content">
										{this.state.futNews.map((news, index) => {
											return (
												<li
													key={`fut-news-home-loop-id-${index}-news-id-${news.id}`}
												>
													<img src={bolinha} />
													<p>{news.title}</p>
												</li>
											);
										})}
									</ul>
									{this.state.loadingNews ? (
										<p className="text-center mb-0 p-0">
											<i className="fas fa-futbol text-white fa-spin"></i>
										</p>
									) : (
										<p className="text-center mb-0 p-0" onClick={this.loadNews}>
											<small>Ver mais</small>
											<br />
											<i className="fa fa-angle-down cursor-pointer"></i>
										</p>
									)}
								</div>
							</div>
							<Simulator />
						</div>

						<div className="col-md-8 col-xs-12">
							<div className="home-support-section mt-4">
								<div>
									<h3>
										<strong>Precisa de ajuda?</strong>
									</h3>
									<p>Fale com o suporte:</p>
								</div>
								<div className="home-support-section-icons">
									<div
										onClick={this.goToWhatsAppSupport}
										style={{ cursor: "pointer" }}
									>
										<i className="text-white fa-brands fa-whatsapp" />
									</div>

									<div>
										<a
											href="mailto:contato@panzers.com.br"
											target="_blank"
											rel="noopener"
										>
											<i className="text-white fa-regular fa-envelope" />
										</a>
									</div>
									<div>
										<a
											href="https://www.instagram.com/panzer.soft/"
											target="_blank"
											rel="noopener"
										>
											<i className="text-white fa-brands fa-instagram" />
										</a>
									</div>
								</div>
							</div>
						</div>
					</div>
				</Page>
			</React.Fragment>
		);
	}
}
