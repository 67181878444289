import React, { useState } from "react";
import Container from "./Container";

const simulatorSteps = [
	{
		title: "Qual o valor da sua Stake?",
		subtitle: "*Stake é o valor usado por aposta.",
		buttonContent: "Avançar",
	},
	{
		title: "Qual produto você quer calcular?",
		subtitle: "*Selecione uma opção",
		buttonContent: "Avançar",
	},
	{
		title: "Qual o período desejado?",
		subtitle: "*Selecione uma opção",
		buttonContent: "Ver resultados",
	},
	{
		title: "Qual o período desejado?",
		subtitle: "*Selecione uma opção",
		buttonContent: "Ver resultados",
	},
];

const Simulator = () => {
	const [actualStepper, setActualStepper] = useState(0);
	const [userValue, setUserValue] = useState();
	const [actualPlan, setActualPlan] = useState();
	const [actualPeriod, setActualPeriod] = useState();

	return (
		<div style={{ display: "flex", flexDirection: "column" }}>
			<Container
				title={simulatorSteps[actualStepper].title}
				subtitle={simulatorSteps[actualStepper].subtitle}
				content={simulatorSteps[actualStepper].content}
				buttonContent={simulatorSteps[actualStepper].buttonContent}
				actualStepper={actualStepper}
				setActualStepper={setActualStepper}
				setUserValue={setUserValue}
				userValue={userValue}
				actualPlan={actualPlan}
				setActualPlan={setActualPlan}
				actualPeriod={actualPeriod} 
				setActualPeriod={setActualPeriod}
			/>
		</div>
	);
};

export default Simulator;
