import React, { useEffect, useState } from "react";
import { ContentContainer1 } from "./styles";
import CheckBoxList from "../CheckBoxList";
import { getResults } from "../../../../../axios";

const Content = ({
	actualStepper,
	setUserValue,
	userValue,
	actualPlan,
	setActualPlan,
	actualPeriod,
	setActualPeriod,
	resultsRes,
}) => {
	const planOptions = resultsRes?.map((result) => result.plan);
	const periodOptions = resultsRes?.[0]?.details.map((result) => result.period);

	if (actualStepper === 0) {
		return (
			<ContentContainer1>
				<div className="slide-animation">
					<label>R$</label>
					<input
						type="number"
						step="0.01"
						placeholder="valor da sua stake"
						value={userValue}
						onChange={(e) => setUserValue(e.target.value)}
					/>
				</div>
			</ContentContainer1>
		);
	}

	if (actualStepper === 1) {
		return (
			<ContentContainer1>
				<CheckBoxList
					options={planOptions}
					actual={actualPlan}
					setActual={setActualPlan}
				/>
			</ContentContainer1>
		);
	}

	if (actualStepper === 2) {
		return (
			<ContentContainer1>
				<CheckBoxList
					options={periodOptions}
					actual={actualPeriod}
					setActual={setActualPeriod}
				/>
			</ContentContainer1>
		);
	}
};

export default Content;
