import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";

import Login from './login'
import Register from './register'

export default function Public(props) {
    return <BrowserRouter>
        <Routes>
            <Route path="/login" element={<Login />} />
            <Route path="/register" element={<Register />} />
            <Route path="/" element={<Login/>} />
        </Routes>
    </BrowserRouter>
}
