import React from "react";
import checkOk from "../../../../../assets/images/simbolo-correto.svg";
import { CheckItem, ContainerCheckList } from "./styles";
const CheckBoxList = ({ options, actual, setActual }) => {
	return (
		<ContainerCheckList>
			{options?.map((option, index) => {
				return (
					<CheckItem
						isActual={actual === option}
						key={option}
						className="slide-animation"
					>
						<button
							onClick={() => {
								setActual(option);
							}}
						>
							{actual === option && (
								<img src={checkOk} alt="check" width={13} height={13} />
							)}
						</button>
						<p>{option}</p>
					</CheckItem>
				);
			})}
		</ContainerCheckList>
	);
};

export default CheckBoxList;
