import React from "react";
import { BrowserRouter, Routes, Route, Link } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import ListUsers from "./users/list";
import EditUser from "./users/edit";
import Settings from "./settings";
import History from "./LogHistory";
import Tutorials from "./tutorials";

import "./style.css";

export default function BackOffice(props) {
	const [activePage, setActivePage] = React.useState("/");
	const [loading, setLoading] = React.useState(false);

	const useUnload = (fn) => {
		const cb = React.useRef(fn);

		React.useEffect(() => {
			const onUnload = cb.current;
			window.addEventListener("beforeunload", onUnload);
			return () => {
				window.removeEventListener("beforeunload", onUnload);
			};
		}, [cb]);
	};

	function navigate(e) {
		setTimeout(() => {
			loadActivePage();
		}, 100);
	}

	useUnload(async (e) => {
		e.preventDefault();
		const token = window.localStorage.getItem(
			process.env.REACT_APP_SITE_STORAGE_KEY_API_KEY
		);
		const refresh = window.localStorage.getItem(
			process.env.REACT_APP_SITE_STORAGE_KEY_REFRESH_KEY
		);
		const role = window.localStorage.getItem(
			process.env.REACT_APP_SITE_STORAGE_KEY_USER_ROLE
		);
		const userId = window.localStorage.getItem("user");
		const pathname = window.location.pathname;
		if (
			pathname !== "/login" &&
			pathname !== "/register" &&
			pathname !== "/exit"
		) {
			window.localStorage.clear();
			e.returnValue = "Deseja sair da página?";
			setTimeout(() => {
				window.localStorage.setItem(
					process.env.REACT_APP_SITE_STORAGE_KEY_API_KEY,
					token
				);
				window.localStorage.setItem(
					process.env.REACT_APP_SITE_STORAGE_KEY_REFRESH_KEY,
					refresh
				);
				window.localStorage.setItem(
					process.env.REACT_APP_SITE_STORAGE_KEY_USER_ROLE,
					role
				);
				window.localStorage.setItem("user", userId);
			}, 500);
		}
	});

	async function loadActivePage() {
		const pathname = window.location.pathname;
		const token = window.localStorage.getItem(
			process.env.REACT_APP_SITE_STORAGE_KEY_API_KEY
		);
		if (!token || pathname === "/exit") {
			setLoading(true);
			window.localStorage.clear();
			setTimeout(() => {
				window.location.href = "/login";
			}, 1000);
			setLoading(false);
		}
	}

	function inactivityTime() {
		let time;
		window.onload = resetTimer;
		document.onmousemove = resetTimer;
		document.onkeyup = resetTimer;
		document.onkeypress = resetTimer;

		function logout() {
			window.localStorage.clear();
			window.location.href = "/login";
		}
		function resetTimer() {
			clearTimeout(time);
			time = setTimeout(logout, 10 * 60 * 1000); // 10 minutes
		}
	}

	React.useEffect(() => {
		loadActivePage();
		inactivityTime();
	}, []);
	return (
		<BrowserRouter>
			<div
				className="container-fluid background-adm"
				style={{
					minHeight: "100vh",
				}}
			>
				<HandleInactivity />
				<div
					beforeUnload="checkClose"
					className="row header-adm py-4 mb-0 pb-2"
				>
					<div className="col-12 d-flex flex-row top-menu">
						<div>
							<Link to="/"></Link>
						</div>
						<div className="backoffice-top-menu">
							<ul>
								<li>
									<Link
										id="home"
										onClick={navigate}
										className={`text-white ${
											activePage === "home"
												? "backoffice-top-menu-selected"
												: ""
										}`}
										to="/"
									>
										<i className="fa-regular fa-circle-user"></i> Clientes
									</Link>
								</li>
								<li>
									<Link
										id="settings"
										onClick={navigate}
										className={`text-white ${
											activePage === "settings"
												? "backoffice-top-menu-selected"
												: ""
										}`}
										to="/settings"
									>
										<i className="fa-solid fa-gear"></i> Configurações
									</Link>
								</li>
								<li>
									<Link
										id="tutorials"
										onClick={navigate}
										className={`text-white ${
											activePage === "tutorials"
												? "backoffice-top-menu-selected"
												: ""
										}`}
										to="/tutorials"
									>
										<i className="fa-solid fa-chalkboard"></i> Tutoriais
									</Link>
								</li>
								<li>
									<Link
										id="history"
										onClick={navigate}
										className={`text-white ${
											activePage === "history"
												? "backoffice-top-menu-selected"
												: ""
										}`}
										to="/history"
									>
										<i className="fa-regular fa-rectangle-list"></i> Log
									</Link>
								</li>
								<li>
									<Link
										id="exit"
										onClick={navigate}
										className="text-white "
										to="/exit"
									>
										<i className="fa-solid fa-power-off"></i> Sair
									</Link>
								</li>
							</ul>
						</div>
					</div>
				</div>
				<div className="row p-5">
					<Routes>
						<Route path="/" element={<ListUsers />} />
						<Route path="/user/:id" element={<EditUser />} />
						<Route path="/settings" element={<Settings />} />
						<Route path="/tutorials" element={<Tutorials />} />
						<Route path="/history" element={<History />} />
						<Route path="*" element={<ListUsers />} />
					</Routes>
				</div>
			</div>
			<ToastContainer />
		</BrowserRouter>
	);
}

function HandleInactivity() {
	function inactivityTime() {
		let time;
		window.onload = resetTimer;
		document.onmousemove = resetTimer;
		document.onkeyup = resetTimer;
		document.onkeypress = resetTimer;

		function logout() {
			window.localStorage.clear();
			window.location.href = "/";
		}
		function resetTimer() {
			clearTimeout(time);
			time = setTimeout(logout, 10 * 60 * 1000); // 10 minutes
		}
	}

	React.useEffect(() => {
		inactivityTime();
	}, []);
}
