import * as React from "react";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import LogHistory from "./usersHistory";
import LogStakesHistory from "./stakesHistory";
import "./styles.css";

function TabPanel(props) {
	const { children, value, index, ...other } = props;

	return (
		<div
			role="tabpanel"
			hidden={value !== index}
			id={`full-width-tabpanel-${index}`}
			aria-labelledby={`full-width-tab-${index}`}
			{...other}
		>
			{value === index && (
				<Box sx={{ p: 3 }}>
					<Typography>{children}</Typography>
				</Box>
			)}
		</div>
	);
}

function a11yProps(index) {
	return {
		id: `full-width-tab-${index}`,
		"aria-controls": `full-width-tabpanel-${index}`,
	};
}

export default function FullWidthTabs() {
	const [value, setValue] = React.useState(0);

	const handleChange = (_, newValue) => {
		setValue(newValue);
	};

	return (
		<div className="container">
			<div className="row">
				<div className="col-12 text-white">
					<h2>Histórico</h2>
				</div>
			</div>
			<Box sx={{ bgcolor: "#333", width: "100%" }}>
				<Tabs
					value={value}
					onChange={handleChange}
					textColor="inherit"
					indicatorColor="primary"
					style={{ color: "white", borderColor: "white" }}
				>
					<Tab label="Users" {...a11yProps(0)} />
					<Tab label="Stakes" {...a11yProps(1)} />
				</Tabs>
				<TabPanel value={value} index={0}>
					<LogHistory />
				</TabPanel>
				<TabPanel value={value} index={1}>
					<LogStakesHistory />
				</TabPanel>
			</Box>
		</div>
	);
}
