import React from "react";
import { FormControl, ButtonGroup, Button, Modal } from "react-bootstrap";
import {
	getConfig,
	createConfig,
	deleteConfig,
	updateConfig,
} from "../../../axios";
import "./styles.css";
import Results from "./Results";
export default class Settings extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			loading: false,
			loadingSave: false,
			modal: false,
			newObj: {},
			currentObj: {},
			data: [],
		};
		this.onSave = this.onSave.bind(this);
		this.onSetContextObj = this.onSetContextObj.bind(this);
		this.onRemoveContextObj = this.onRemoveContextObj.bind(this);
		this.openDialogDelete = this.openDialogDelete.bind(this);
		this.closeDialogDelete = this.closeDialogDelete.bind(this);
		this.onDelete = this.onDelete.bind(this);
		this.onChangeNewObj = this.onChangeNewObj.bind(this);
		this.onChangeCurrentObj = this.onChangeCurrentObj.bind(this);
		this.onUpdate = this.onUpdate.bind(this);
	}

	onSave() {
		this.setState({ ...this, loading: true });
		createConfig(this.state.newObj)
			.then((resp) => {
				window.location.reload();
			})
			.catch((error) => {
				this.setState({ ...this, loading: false });
			});
	}

	onDelete() {
		this.setState({ ...this, loading: true });
		deleteConfig(this.state.currentObj.id)
			.then(() => {
				window.location.reload();
			})
			.catch((error) => {
				this.setState({ ...this, loading: false });
			});
	}

	onUpdate() {
		this.setState({ ...this, loadingSave: true });
		updateConfig(this.state.currentObj.id, this.state.currentObj)
			.then(() => {
				window.location.reload();
			})
			.catch(() => {
				this.setState({ ...this, loadingSave: false });
			});
	}

	onSetContextObj(e) {
		let currentObj = this.state.data.filter((item) => item.id === e.target.id);
		if (currentObj.length) {
			currentObj = currentObj[0];
			this.setState({ ...this, currentObj });
		}
	}
	onRemoveContextObj() {
		this.setState({ ...this, currentObj: {} });
	}
	openDialogDelete() {
		const { currentObj } = this.state;
		currentObj["showModal"] = true;
		this.setState({ ...this, currentObj });
	}
	closeDialogDelete() {
		if (this.state.loading) {
			return;
		}
		const { currentObj } = this.state;
		currentObj["showModal"] = false;
		this.setState({ ...this, currentObj });
	}
	onChangeNewObj(e) {
		const { name: key, value } = e.target;
		const { newObj } = this.state;
		newObj[key] = value;
		this.setState({ ...this, newObj });
	}
	onChangeCurrentObj(e) {
		const { name: key, value } = e.target;
		const { currentObj } = this.state;
		currentObj[key] = value;
		this.setState({ ...this, currentObj });
	}

	_get() {
		this.setState({ ...this, loading: true });
		getConfig()
			.then((data) => {
				this.setState({ ...this, data: data.results });
				this.setState({ ...this, loading: false });
			})
			.catch(() => {
				this.setState({ ...this, loading: false });
			});
	}
	componentDidMount() {
		this._get();
		document.body.style.overflowY = "auto";
	}
	render() {
		return (
			<>
				<div className="col-12 d-flex gap-5">
					<div className="panel__settings md-m-5">
						<div className="d-flex justify-content-between">
							<h3 className="text-white">Planos</h3>
							<button
								disabled={this.state.loading}
								className="btn btn-primary"
								onClick={() => this.setState({ ...this, modal: true })}
							>
								{this.state.loading ? (
									<i className="fa-spin fa-solid fa-gear"></i>
								) : (
									<p className="m-0 p-0 text-white">+ Plano</p>
								)}
							</button>
						</div>
						{this.state.data.map((config) => {
							return (
								<div
									className="mb-3"
									key={`config-loop-config-item-${config.id}`}
								>
									{this.state.currentObj.id === config.id ? (
										<FormControl
											name="product_name"
											onChange={this.onChangeCurrentObj}
											disabled={this.state.loading}
											className="mb-y select__adm"
											value={
												this.state.currentObj.id === config.id
													? this.state.currentObj.product_name || ""
													: config.product_name || ""
											}
										/>
									) : (
										<strong className="text-white">
											{config.product_name}
										</strong>
									)}
									<FormControl
										name="resource"
										onChange={this.onChangeCurrentObj}
										disabled={this.state.loading}
										className="select__adm"
										onClick={this.onSetContextObj}
										value={
											this.state.currentObj.id === config.id
												? this.state.currentObj.resource || ""
												: config.resource || ""
										}
										id={config.id}
									/>
									{this.state.currentObj.id === config.id ? (
										<FormControl
											name="feature_image_url"
											placeholder="Imagem destacada"
											maxLength={200}
											onChange={this.onChangeCurrentObj}
											disabled={this.state.loading}
											className="mb-y select__adm"
											value={
												this.state.currentObj.id === config.id
													? this.state.currentObj.feature_image_url || ""
													: config.feature_image_url || ""
											}
										/>
									) : (
										<React.Fragment />
									)}
									<div
										style={{
											display:
												this.state.currentObj.id === config.id
													? "block"
													: "none",
										}}
									>
										<ButtonGroup>
											<Button
												onClick={this.onUpdate}
												disabled={this.state.loadingSave || this.state.loading}
												className="p-1"
											>
												<div className="d-flex flex-column">
													<small>
														<i className="fa-solid fa-floppy-disk"></i>
													</small>
													<small className="text-white">
														{this.state.loadingSave ? "salvando..." : "salvar"}
													</small>
												</div>
											</Button>
											<Button
												disabled={this.state.loadingSave || this.state.loading}
												onClick={this.openDialogDelete}
												variant="danger"
											>
												<div className="d-flex flex-column">
													<small>
														<i className="fa-solid fa-trash-can"></i>
													</small>
													<small className="text-white">deletar</small>
												</div>
											</Button>
											<Button
												disabled={this.state.loadingSave || this.state.loading}
												onClick={this.onRemoveContextObj}
												variant="info text-white"
											>
												<div className="d-flex flex-column">
													<small>
														<i className="fa-solid fa-xmark"></i>
													</small>
													<small className="text-white">fechar</small>
												</div>
											</Button>
										</ButtonGroup>
									</div>
								</div>
							);
						})}
					</div>
					<Results />
				</div>
				<Modal show={this.state.modal}>
					<div style={{ background: "rgb(64, 64, 64)" }}>
						<Modal.Header className="text-white">
							Criar um novo Produto
						</Modal.Header>
						<Modal.Body>
							<div className="form-group mb-3">
								<div className="form-label text-white">Nome</div>
								<FormControl
									autoFocus
									name="product_name"
									className="select__adm"
									disabled={this.state.loading}
									onChange={this.onChangeNewObj}
									value={this.state.newObj.product_name}
								/>
							</div>
							<div className="form-group">
								<div className="form-label text-white">URL do painel</div>
								<FormControl
									disabled={this.state.loading}
									name="resource"
									className="select__adm"
									onChange={this.onChangeNewObj}
									value={this.state.newObj.resource}
								/>
							</div>
						</Modal.Body>
						<Modal.Footer className="d-flex justify-content-between">
							<Button
								disabled={this.state.loading}
								onClick={() =>
									this.setState({ ...this, modal: false, newObj: {} })
								}
								variant="danger"
							>
								Cancelar
							</Button>
							<Button disabled={this.state.loading} onClick={this.onSave}>
								Salvar
							</Button>
						</Modal.Footer>
					</div>
				</Modal>
				<Modal
					show={this.state.currentObj.showModal}
					onHide={this.closeDialogDelete}
				>
					<div style={{ background: "rgb(64, 64, 64)" }}>
						<Modal.Header closeButton className="text-white">
							Deseja remover?
						</Modal.Header>
						<Modal.Body>
							<Button
								disabled={this.state.loading}
								onClick={this.onDelete}
								variant="danger"
								className="text-white"
							>
								{this.state.loading ? "Removendo..." : "Remover"}
							</Button>
						</Modal.Body>
					</div>
				</Modal>
			</>
		);
	}
}
