import axios from "axios";
import translate from "./translate"

import { toast } from 'react-toastify'

const { pages } = translate();
const apiUrl = process.env.REACT_APP_API_URL;
const apiVersion = process.env.REACT_APP_API_VERSION;

const strings = pages["login"];
const headers = {
	"Content-Type": "application/json",
};

const instance = axios.create({
	baseURL: `${apiUrl}/api/${apiVersion}`,
	headers,
	timeout: 120000,
});

async function request(data) {
	const token = window.localStorage.getItem(
		process.env.REACT_APP_SITE_STORAGE_KEY_API_KEY
	);

	data["headers"] = {
		authorization: `Bearer ${token}`,
	};

	return new Promise((resolve, reject) => {
		instance(data)
			.then((response) => {
				resolve(response.data);
			})
			.catch((error) => {
				if (error.response) {
					const { status } = error.response;
					if (status === 401) {
						const refresh = window.localStorage.getItem(
							process.env.REACT_APP_SITE_STORAGE_KEY_REFRESH_KEY
						);

						instance
							.post("/token/refresh/", {
								refresh: refresh,
							})
							.then((response) => {
								window.localStorage.setItem(
									process.env.REACT_APP_SITE_STORAGE_KEY_API_KEY,
									response.data.access
								);
								data["headers"] = {
									Authorization: `Bearer ${response.data.access}`,
								};
								instance(data)
									.then((resp) => {
										resolve(resp.data);
									})
									.catch((error) => reject(error));
							})
							.catch((error) => {
								if (error.response) {
									if (error.response.status === 401) {
										window.localStorage.clear();
										window.location.href = "/";
									}
								}
							});
					}
				}
			});
	});
}

async function me() {
	const data = {
		url: "/users/me",
	};
	return request(data);
}
async function loginUser() {
	const data = {
		url: `/users/login/`,
	};
	return request(data);
}
async function setRedis(redisData) {
	 	const data = {
	 		url: `redis/`,
	 		method: "POST",
	 		data: redisData,
	 	};
	 	return request(data);
}
async function reportSessionLog(redisData) {
	const data = {
		url: `redis/session/`,
		method: "POST",
		data: redisData,
	};
	return new Promise((resolve, reject) => {
		instance(data)
			.then((response) => {
				resolve(response.data);
			})
			.catch((err) => reject(err));
	});
}
async function getRedisItems(redisData) {
	 	const data = {
	 		url: `redis/`,
	 		method: "GET",
	 		data: redisData,
	 	};
	 	return new Promise((resolve, reject) => {
	 		instance(data)
	 			.then((response) => {
	 				resolve(response.data);
	 			})
	 			.catch((err) => reject(err));
	 	});
}
async function getRedisUser(redisData) {
	 	const data = {
	 		url: `redis/item/`,
	 		method: "GET",
	 		data: redisData,
	 	};
	 	return new Promise((resolve, reject) => {
	 		instance(data)
	 			.then((response) => {
	 				resolve(response.data);
	 			})
	 			.catch((err) => reject(err));
	 	});
}
async function deleteRedis(redisData) {
	 	const data = {
	 		url: `redis/item/`,
	 		method: "DELETE",
	 		data: redisData,
	 	};
	 	return new Promise((resolve, reject) => {
	 		instance(data)
	 			.then((response) => {
	 				resolve(response.data);
	 			})
	 			.catch((err) => reject(err));
	 	});
}
async function logoutUser(ip) {
	const data = {
		url: `/users/logout/?ip=${ip}`,
	};
	return request(data);
}
async function news() {
	const data = {
		url: `/news`,
	};
	return request(data);
}
async function login(data) {
	return new Promise((resolve, reject) => {
		instance
			.post("token/", data)
			.then((response) => {
				const { access, refresh } = response.data;
				window.localStorage.setItem(
					process.env.REACT_APP_SITE_STORAGE_KEY_API_KEY,
					access
				);
				window.localStorage.setItem(
					process.env.REACT_APP_SITE_STORAGE_KEY_REFRESH_KEY,
					refresh
				);
				window.localStorage.setItem(
					"user",
					JSON.stringify({ acc: access, refr: refresh })
				);
				async function validateSession() {
					 //const ip = await publicIpv4();
					const redisUser = await getRedisItems();
					let ip;
					console.log(ip);
					await me()
						.then((data) => {
							 const redisHasEmail = Object.keys(redisUser).find((key) =>
							 	key.includes(data.email)
							 );
							 const firstIp = redisHasEmail
							 	? redisHasEmail.split("|")[1]
							 	: null;
							 const hasIp = Object.keys(redisUser).find((key) =>
							 	key.includes(ip)
							 );
							 if (
							 	(!data.role && redisHasEmail?.length) ||
							 	(!data.role && firstIp === ip) ||
							 	(!data.role && hasIp?.length)
							 ) {
							 	reportSessionLog({
							 		key: data.id,
							 		value: `${data.email},${firstIp}|${ip}`,
							 	});
							 	toast.error(strings.errors.session_error, {
							 		toastId: "error-login",
							 		position: "top-left",
							 	});
							 	window.localStorage.clear();
							 	reject({ error: strings.errors.session_error });
							 } else {
							resolve(response.data);
							 }
						})
						.catch((error) => {
							console.error(error);
						});
				}validateSession();
			})
			.catch((error) => {
				reject(error);
			});
	});
}
async function register(data) {
	return new Promise((resolve, reject) => {
		instance
			.post("users/", data)
			.then(async (resp) => {
				window.localStorage.setItem(
					process.env.REACT_APP_SITE_STORAGE_KEY_USER_DATA,
					JSON.stringify(resp.data)
				);
				const loginRes = await login(data);
				resolve(loginRes);
			})
			.catch((error) => {
				reject(error);
			});
	});
}
async function getUserContract() {
	const data = {
		url: "users/contract/",
	};
	return request(data);
}


async function listUsers(params = {}) {
	const data = {
		url: "backoffice/users/",
		method: "GET",
		params,
	};
	return request(data);
}
async function updateUser(id, userData) {
	const data = {
		url: `users/${id}/`,
		method: "PUT",
		data: userData,
	};
	return request(data);
}
async function getUser(id) {
	const data = {
		url: `backoffice/users/${id}/`,
	};
	return request(data);
}
async function updateUserContract(id, contractData) {
	const data = {
	url: `contract/${id}/`,
	method: "PUT",
	data: contractData,
	};
	return request(data);
	}


async function getActivesContractsHistory(page) {
	if (page==""){
		page = 1
	}
	const page_param = "?page="+page
	return request({ url: `/users/contracts/`});
}

async function getUserPaymentHistory(id) {
	const data = {
		url: `backoffice/users/${id}/payment-history/`,
		method: "GET",
	};
	return request(data);
}
async function deleteUser(id) {
	const data = {
		url: `backoffice/users/${id}/`,
		method: "DELETE",
	};
	return request(data);
}
async function resetUserPassword(id, newPassword) {
	const data = {
		url: `backoffice/users/${id}/reset-password/`,
		method: "POST",
		data: { new_password: newPassword },
	};
	return request(data);
}
async function getConfig() {
	const data = {
		url: `config/`,
		method: "GET",
		params: {
			page: undefined,
		},
	};
	return request(data);
}
async function getTutorials() {
	const data = {
		url: `tutorials/`,
		method: "GET",
	};
	return request(data);
}
export async function getTutorial(id) {
	const data = {
		url: `tutorials/${id}`,
		method: "GET",
	};
	return request(data);
}
export async function createTutorials(tutorialData) {
	const data = {
		url: `tutorials/`,
		method: "POST",
		data: tutorialData,
	};
	return request(data);
}
export async function updateTutorials(id, tutorialData) {
	const data = {
		url: `tutorials/${id}/`,
		method: "PUT",
		data: tutorialData,
	};
	return request(data);
}

export async function getResults() {
	const data = {
		url: `results/`,
		method: "GET",
	};
	return request(data);
}
export async function createResults(resultsData) {
	const data = {
		url: `results/new/`,
		method: "POST",
		data: resultsData,
	};
	return request(data);
}
export async function updateResults(id, resultsData) {
	const data = {
		url: `results/${id}/`,
		method: "PUT",
		data: resultsData,
	};
	return request(data);
}

export async function deleteResult(id) {
	const data = {
		url: `results/${id}/`,
		method: "DELETE",
	};
	return request(data);
}

export async function deleteTutorial(id) {
	const data = {
		url: `tutorials/${id}/`,
		method: "DELETE",
	};
	return request(data);
}

async function createConfig(configData) {
	const data = {
		url: `config/`,
		method: "POST",
		data: configData,
	};
	return request(data);
}
async function deleteConfig(id) {
	const data = {
		url: `config/${id}/`,
		method: "DELETE",
	};
	return request(data);
}
async function updateConfig(id, configData) {
	const data = {
		url: `config/${id}/`,
		method: "PUT",
		data: configData,
	};
	return request(data);
}
async function getHistory(params = {}) {
	return request({ url: "backoffice/users/admin-history", params });
}

async function getStakesHistory(params = {}, page) {
	if (page==""){
		page = 1
	}
	const page_param = "?page="+page
	return request({ url: `/users/stakes/${page_param}`, params});
}


async function reportStakesLog(stakes,plan) {
	const data = {
		url: `/users/stake/`,
		method: "POST",
		data: {
			"stake": stakes,
			"plan_id": plan
		},
	};
	
	console.log({
		"stake": stakes,
		"plan_id": plan
	})
	return request(data)
}

export default instance;


export {
	createConfig,
	deleteConfig,
	deleteRedis,
	deleteUser,
	getConfig,
	getHistory,
	getRedisItems,
	getRedisUser,
	getStakesHistory,
	getTutorials,
	getUser,
	getUserContract,
	getActivesContractsHistory,
	getUserPaymentHistory,
	listUsers,
	login,
	loginUser,
	logoutUser,
	me,
	news,
	register,
	reportSessionLog,
	reportStakesLog,
	resetUserPassword,
	setRedis,
	updateConfig,
	updateUser,
	updateUserContract,
};

