import React from "react";
import { Button, Modal, FormControl } from "react-bootstrap"
import { getTutorials, createTutorials, updateTutorials, deleteTutorial } from "../../../axios";
import { toast } from "react-toastify"
import { Editor, EditorState, RichUtils } from 'draft-js';
import 'draft-js/dist/Draft.css';

import CardTutorial from "./components/CardTutorial";

function validateURLField(value = "") {
    var urlRegex = '^(?!mailto:)(?:(?:http|https|ftp)://)(?:\\S+(?::\\S*)?@)?(?:(?:(?:[1-9]\\d?|1\\d\\d|2[01]\\d|22[0-3])(?:\\.(?:1?\\d{1,2}|2[0-4]\\d|25[0-5])){2}(?:\\.(?:[0-9]\\d?|1\\d\\d|2[0-4]\\d|25[0-4]))|(?:(?:[a-z\\u00a1-\\uffff0-9]+-?)*[a-z\\u00a1-\\uffff0-9]+)(?:\\.(?:[a-z\\u00a1-\\uffff0-9]+-?)*[a-z\\u00a1-\\uffff0-9]+)*(?:\\.(?:[a-z\\u00a1-\\uffff]{2,})))|localhost)(?::\\d{2,5})?(?:(/|\\?|#)[^\\s]*)?$';
    var url = new RegExp(urlRegex, 'i');
    return value.match(url)
}

export default class Tutorials extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            tutorials: [],
            obj: {},
            fetching: true,
            loading: true,
            showModal: false,
            slateEditor: {},
            data: {},
            showModalYoutubeIframe: false,
            showModalDelete: false
        }
        this.onCloseModal = this.onCloseModal.bind(this)
        this.onChangeData = this.onChangeData.bind(this)
        this.onSave = this.onSave.bind(this)
        this.closeModalEmbed = this.closeModalEmbed.bind(this)
        this.closeModalDelete = this.closeModalDelete.bind(this)
        this.deleteTutorial = this.deleteTutorial.bind(this)
        this.editItem = this.editItem.bind(this)
        this.openModalYtEmbed = this.openModalYtEmbed.bind(this)
        this.openModalDelete = this.openModalDelete.bind(this)
    }

    async get() {
        this.setState({ ...this, fetching: true, loading: true })
        await getTutorials()
            .then(result => this.setState({ ...this, tutorials: result.results }))
        setTimeout(() => {
            this.setState({ ...this, fetching: false, loading: false })
        }, 200)
    }

    finalize() {
        this.setState({ ...this, data: {}, showModal: false, loading: false })
        toast.success("Tutorial Salvo", {
            toastId: "tutorial-saved"
        })
        this.get()
    }

    async onSave() {
        this.setState({ ...this, loading: true })
        if (this.state.data.id) {
            await updateTutorials(this.state.data.id, this.state.data)
                .then(() => this.finalize())
                .catch(() => this.finalize())
        } else {
            await createTutorials(this.state.data)
                .then(() => this.finalize())
                .catch(() => this.finalize())
        }
    }

    async deleteTutorial() {
        this.setState({ ...this, loading: true })
        deleteTutorial(this.state.data.id)
            .then(() => {
                toast.success("Tutorial removido", { toastId: "remove-tutorial" })
                this.setState({ ...this, data: {}, showModalDelete: false })
                this.get()
            })
    }

    editItem(data) {
        this.setState({ data, showModal: true })
    }

    onCloseModal() {
        this.setState({ data: {}, showModal: false })
    }
    openModalYtEmbed(item) {
        const videoId = item["video_url"].split("watch?v=")[1]
        const { data } = this.state
        data["embed"] = `https://www.youtube.com/embed/${videoId}`
        this.setState({ showModalYoutubeIframe: true, data })
    }
    closeModalEmbed() {
        this.setState({ showModalYoutubeIframe: false, data: {} })
    }
    openModalDelete(data) {
        this.setState({ showModalDelete: true, data })
    }
    closeModalDelete() {
        this.setState({ showModalDelete: false, data: {} })
    }
    onChangeData(e) {
        const { data } = this.state
        const { name: key, value } = e.target
        data[key] = value
        this.setState({ ...this, data })
    }

    componentDidMount() {
        this.get()
    }
    render() {
        return <div className="container">
            <div className={` ${this.state.fetching ? "row" : "d-none"} `}>
                <div className="col-12">
                    <p className="lead text-center">
                        Carregando <i className="fas fa-spinner fa-spin"></i>
                    </p>
                </div>
            </div>
            <div className={` ${!this.state.fetching ? "row" : "d-none"} `}>
                <div className="col-12">
                    <Button onClick={() => this.setState({ ...this, showModal: true })}>
                        Cadastrar novo tutorial
                    </Button>
                </div>
                {
                    this.state.tutorials.map(item => {
                        return <CardTutorial
                            editItem={this.editItem}
                            openModalYtEmbed={this.openModalYtEmbed}
                            openModalDelete={this.openModalDelete}
                            item={item}
                            showFooter />
                    })
                }
            </div>
            <Modal
                show={this.state.showModalYoutubeIframe}
                onHide={this.closeModalEmbed}>
                <Modal.Header closeButton>
                    <Modal.Title>
                        <h3>
                            {this.state.data.title}
                        </h3>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <iframe
                        className="w-100"
                        src={this.state.data.embed}
                        title="YouTube video player"
                        frameborder="0"
                        height={340}
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        allowfullscreen />
                </Modal.Body>
            </Modal>
            <Modal show={this.state.showModalDelete} onHide={this.closeModalDelete}>
                <Modal.Header closeButton>
                    <Modal.Title>
                        <h3>
                            Deseja excluir o item?
                        </h3>
                    </Modal.Title>

                </Modal.Header>
                <Modal.Footer>
                    <Button
                        disabled={this.state.loading}
                        onClick={this.deleteTutorial}
                        variant="danger">
                        <i className="fas fa-tash-alt"></i> Deletar <i className={
                            this.state.loading ? "fas fa-spinner fa-spin" : ""
                        }></i>
                    </Button>
                </Modal.Footer>
            </Modal>
            <Modal show={this.state.showModal} size="lg" onHide={this.onCloseModal}>
                <Modal.Header closeButton>
                    <Modal.Title>
                        <h2 className="text-center">
                            {
                                this.state.data.id
                                    ? "Editar Item"
                                    : "Criar Item"
                            }
                        </h2>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="form-group">
                        <div className="form-label">
                            Título
                        </div>
                        <FormControl
                            name="title"
                            disabled={this.state.loading}
                            onChange={this.onChangeData}
                            value={this.state.data["title"]}
                        />
                    </div>
                    <div className="form-group">
                        <div className="form-label">
                            Descrição
                        </div>
                        <FormControl
                            name="description"
                            disabled={this.state.loading}
                            onChange={this.onChangeData}
                            value={this.state.data["description"]}
                        />
                    </div>
                    <div className="form-group">
                        <div className="form-label">
                            Conteúdo
                        </div>
                        <FormControl
                            name="text"
                            disabled={this.state.loading}
                            as="textarea"
                            onChange={this.onChangeData}
                            value={this.state.data["text"]}
                            rows="4" />
                    </div>
                    <div className="form-group">
                        <div className="form-label">
                            URL da imagem em destaque
                        </div>
                        <FormControl
                            name="feature_image_url"
                            disabled={this.state.loading}
                            onChange={this.onChangeData}
                            value={this.state.data["feature_image_url"]}
                            maxLength={200}
                        />
                    </div>
                    <div className="form-group">
                        <div className="form-label">
                            URL do vídeo no YouTube
                        </div>
                        <FormControl
                            name="video_url"
                            maxLength={200}
                            disabled={this.state.loading}
                            onChange={this.onChangeData}
                            value={this.state.data["video_url"]}
                        />
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button
                        disabled={this.state.loading}
                        onClick={this.onSave}>
                        Salvar <i className={
                            this.state.loading ? "fas fa-spinner fa-spin" : ""
                        }></i>
                    </Button>
                </Modal.Footer>
            </Modal>
        </div >
    }
}

class MyEditor extends React.Component {
    constructor(props) {
        super(props);
        this.state = { editorState: EditorState.createEmpty() };
        this.onChange = editorState => this.setState({ editorState });
    }
    show() {
        console.log(this.state.editorState)
    }
    _onBoldClick() {
        this.onChange(RichUtils.toggleInlineStyle(this.state.editorState, 'BOLD'));
    }

    render() {
        return <div className="border border-primary">
            <button onClick={() => this.show()}>Bold</button>
            <button onClick={this._onBoldClick.bind(this)}>Bold</button>
            <Editor autofocus editorState={this.state.editorState} onChange={this.onChange} />
        </div>
    }
}