import LogoPanzer from '../../../../assets/images/logonova.svg'
import styled from 'styled-components'

const ImgLogo = styled.img`
    max-width: 40px;
    margin-bottom: -20px !important;
`

export default () => <div>
    <ImgLogo
        className="img-fluid img-logo mx-auto "
        src={LogoPanzer} />
</div>