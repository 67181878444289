/* eslint-disable import/no-anonymous-default-export */
export default {
	"pt-BR": {
		pages: {
			login: {
				title: "Bem vindo ao Panzer",
				subtitle: "",
				session_error: "Apenas uma sessão por uso é permitida",
				email_field_label: "Email",
				passowrd_field_label: "Senha",
				save_email_label: "Lembrar login",
				forgot_password_label: "Esqueceu sua senha?",
				forgot_password_modal_title: "Recuperar senha",
				forgot_password_modal_subtitle: "Informe seu email de acesso",
				forgot_password_modal_button_title: "Recuperar senha",
				button_login_label: "Entrar",
				create_account_label: "Não possui conta?",
				create_account_link_label: "Cadastre-se",
				reset_password_modal_label: "Redefinir senha",
				reset_password_modal_password_label: "Nova senha",
				reset_password_modal_confirm_password_label: "Confirmar senha",
				reset_password_modal_button_label: "Atualizar senha",
				reset_password_success_message:
					"As intruções de redefinição de senha foram enviadas ao email!",
				reset_password_success_change: "A senha foi redefinida",
				errors: {
					login_fail: "Não foi possível realizar o login.",
					session_error: "Apenas uma sessão por uso é permitida",
				},
			},
			register: {
				title: "Faça seu cadastro",
				subtitle: "Inicie seu cadastro",
				description:
					"Lucre mais com inteligência artificial recomendando as melhores apostas",
				call_to_action: "Faça seu cadastro",
				register_form: {
					step_one: "passo",
					step_two: "passo",
					name_field_label: "Nome completo",
					phone_number_field_label: "Telefone",
					personal_document_field_label: "CPF",
					email_field_label: "E-mail",
					password_field_label: "Senha",
					confirm_password_field_label: "Confirme sua senha",
					register_button_field_label_loading: "cadastrando ...",
					register_button_field_label: "cadastrar minha conta",
					register_button_next_stage_field_label: "Próxima etapa",
					errors: {
						register_error:
							"Não foi possível realizar o cadastro. Verifique os dados e tente novamente",
						cpf_registred_error: "Este CPF já está registrado!",
						phone_number_registred_error:
							"Este Número de telefone já está associado a uma outra conta.",
						email: "",
					},
				},
			},
			home: {
				title: "",
				subtitle: "",
				news_section: {
					title: "",
					subtitle: "",
				},
				banner_call_to_action: "",
				middle_section_view_panzer: "",
				middle_section_view_panzer_button_label: "",
				support_area: {
					title: "",
					subtitle: "",
					whatsapp_icon_label: "",
					email_icon_label: "",
					tutorials_icon_label: "",
				},
				tutorials_area: {
					title: "",
					subtitle: "",
					description: "",
				},
			},
			products: {
				title: "",
				subtitle: "",
			},
			tutorials: {
				title: "",
				subtitile: "",
			},
			support: {
				title: "",
				subtitle: "",
				whatsapp_icon_label: "",
				email_icon_label: "",
				tutorials_icon_label: "",
			},
			more_products: {
				title: "",
				subtitle: "",
			},
		},
	},
	"es-ES": {
		pages: {
			login: {
				title: "¡Bienvenido a Panzer!",
				subtitle: "Ingrese a su cuenta",
				session_error: "Solo se permite una sesión por uso",
				email_field_label: "Email",
				passowrd_field_label: "Contraseña",
				save_email_label: "Guardar este correo electrónico",
				forgot_password_label: "Olvidaste tu contraseña?",
				forgot_password_modal_title: "Recuperar contraseña",
				forgot_password_modal_subtitle:
					"Ingrese su correo electrónico de inicio de sesión",
				forgot_password_modal_button_title: "Recuperar contraseña",
				button_login_label: "Iniciar sesión",
				create_account_label: "¿No tienes una cuenta?",
				create_account_link_label: "Crea una cuenta",
				reset_password_modal_label: "Redefinir contraseña",
				reset_password_modal_password_label: "Nueva contraseña",
				reset_password_modal_confirm_password_label: "confirmar seña",
				reset_password_modal_button_label: "Actualiza contraseña",
				reset_password_success_message:
					"¡Las instrucciones para restablecer la contraseña han sido enviadas por correo electrónico!",
				reset_password_success_change: "Contraseña se ha restablecido",
				errors: {
					login_fail: "Incapaz de iniciar sesión.",
					session_error: "Solo se permite una sesión por uso",
				},
			},
			register: {
				title: "",
				subtitle: "",
				register_form: {
					step_one: "",
					step_two: "",
					name_field_label: "",
					phone_number_field_label: "",
					personal_document_field_label: "",
					email_field_label: "",
					password_field_label: "",
					confirm_password_field_label: "",
					register_button_field_label: "",
					register_button_next_stage_field_label: "",
					errors: {},
				},
			},
			home: {
				title: "",
				subtitle: "",
				news_section: {
					title: "",
					subtitle: "",
				},
				banner_call_to_action: "",
				middle_section_view_panzer: "",
				middle_section_view_panzer_button_label: "",
				support_area: {
					title: "",
					subtitle: "",
					whatsapp_icon_label: "",
					email_icon_label: "",
					tutorials_icon_label: "",
				},
				tutorials_area: {
					title: "",
					subtitle: "",
					description: "",
				},
			},
			products: {
				title: "",
				subtitle: "",
			},
			tutorials: {
				title: "",
				subtitile: "",
			},
			support: {
				title: "",
				subtitle: "",
				whatsapp_icon_label: "",
				email_icon_label: "",
				tutorials_icon_label: "",
			},
			more_products: {
				title: "",
				subtitle: "",
			},
		},
	},
	"en-US": {
		pages: {
			login: {
				title: "Welcome to Panzer!",
				subtitle: "",
				session_error: "Only one session per use is allowed",
				email_field_label: "Email",
				passowrd_field_label: "Password",
				save_email_label: "Save this email",
				forgot_password_label: "Forgot your password?",
				forgot_password_modal_title: "Recover Password",
				forgot_password_modal_subtitle: "Enter your login email",
				forgot_password_modal_button_title: "Recover Password",
				button_login_label: "Login",
				create_account_label: "Don't have an account?",
				create_account_link_label: "create account",
				reset_password_modal_label: "Reset password",
				reset_password_modal_password_label: "New password",
				reset_password_modal_confirm_password_label: "Confirm password",
				reset_password_modal_button_label: "Refresh password",
				reset_password_success_message:
					"Password reset instructions have been sent to email!",
				reset_password_success_change: "Password has been reset",
				errors: {
					login_fail: "Unable to login.",
					session_error: "Only one session per user is allowed",
				},
			},
			register: {
				title: "Register",
				subtitle: "Start your registration",
				description:
					"Profit more with artificial intelligence by recommending the best bets",
				call_to_action: "Make your registration",
				register_form: {
					step_one: "step",
					step_two: "step",
					name_field_label: "Full name",
					phone_number_field_label: "Phone",
					personal_document_field_label: "CPF",
					email_field_label: "Email",
					password_field_label: "Password",
					confirm_password_field_label: "Confirm your password",
					register_button_field_label_loading: "Registering ...",
					register_button_field_label: "Finish Registration",
					register_button_next_stage_field_label: "Next step",
					errors: {
						register_error:
							"It was not possible to register. Please check the data and try again",
						cpf_registered_error: "This CPF is already registered!",
						phone_number_registred_error:
							"This Phone Number is already associated with another account.",
						email: "",
					},
				},
			},
			home: {
				title: "",
				subtitle: "",
				news_section: {
					title: "",
					subtitle: "",
				},
				banner_call_to_action: "",
				middle_section_view_panzer: "",
				middle_section_view_panzer_button_label: "",
				support_area: {
					title: "",
					subtitle: "",
					whatsapp_icon_label: "",
					email_icon_label: "",
					tutorials_icon_label: "",
				},
				tutorials_area: {
					title: "",
					subtitle: "",
					description: "",
				},
			},
			products: {
				title: "",
				subtitle: "",
			},
			tutorials: {
				title: "",
				subtitle: "",
			},
			support: {
				title: "",
				subtitle: "",
				whatsapp_icon_label: "",
				email_icon_label: "",
				tutorials_icon_label: "",
			},
			more_products: {
				title: "",
				subtitle: "",
			},
		},
	},
};
