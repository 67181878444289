/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import { Table, Button, FormControl } from "react-bootstrap";
import { getStakesHistory } from "../../../../axios";

import { RiWhatsappLine } from "react-icons/ri/";
import Pagination from "../../../../components/pagination/index";

let PageSize = 10;

const styles = {
	root: {
		color: "white",
	},
	ul: {
		"& .pagination-item": {
			color: "white",
		},
	},
};

const LogStakesHistory = () => {
	const [histories, setHistories] = React.useState([]);
	const [pages, setPages] = React.useState(0);
	const [actualPage, setActualPage] = React.useState(1);
	const [params, setParams] = React.useState({});

	async function getLogHistory(pageToChange) {
		setHistories([]);
		let page = pageToChange !== undefined ? pageToChange : 1;
		await getStakesHistory(params, page).then((data) => {
			setHistories(data.results);
			setPages(Math.ceil(data.count / 10));
		});
		setActualPage(page);
	}

	function onChange(e) {
		const newParams = { ...params };
		newParams[e.target.name] = e.target.value;
		setParams(newParams);
	}

	React.useEffect(() => {
		setTimeout(getLogHistory, 200);
	}, []);

	return (
		<div className="container">
			<div className="d-flex align-items-center gap-5">
				<div className="d-flex justify-content-start mb-2">
					<div className="form-group">
						<div className="form-label text-white">Data inicial</div>
						<FormControl
							name="start_date"
							type="date"
							onChange={onChange}
							className="input__date select__adm"
						/>
					</div>
					<div className="mx-3"></div>
					<div className="form-group">
						<div className="form-label text-white">Data Final</div>
						<FormControl
							name="end_date"
							type="date"
							onChange={onChange}
							className="input__date select__adm"
						/>
					</div>
				</div>
				<Button onClick={getLogHistory} className="filter__button">
					Filtrar
				</Button>
			</div>

			<div className="col-x">
				<Table bordered responsive hover size="sm" className="list-users-table">
					<thead>
						<tr>
							<th className="text-center">Nome</th>
							<th className="text-center">Data/Hora</th>
							<th className="text-center">Email</th>
							<th className="text-center">Cpf</th>
							<th className="text-center">Stake</th>
							<th className="text-center">Whats</th>
						</tr>
					</thead>
					<tbody>
						{histories.map((history, index) => {
							let dd = `${
								history.user.phone_ddd != null ? history.user.phone_ddd : ""
							}`;
							let whats_link = `http://wa.me/55${dd}${history.user.phone}`;
							return (
								<tr>
									<td className="text-center">{history.user.name}</td>
									<td className="text-center">{history.created_date}</td>
									<td className="text-center">{history.user.email}</td>
									<td className="text-center">{history.user.cpf}</td>
									<td className="text-center">{history.stake_value}</td>
									<td className="text-center">
										<a
											target="blank"
											href={whats_link}
											style={{ color: "white" }}
										>
											<RiWhatsappLine />
											{whats_link}
										</a>
									</td>
								</tr>
							);
						})}
					</tbody>
				</Table>

				<div className="form-label">
					<Pagination
						currentPage={actualPage}
						totalCount={pages * PageSize}
						pageSize={PageSize}
						onPageChange={(page) => {
							getLogHistory(page);
						}}
					/>
				</div>
			</div>
		</div>
	);
};

export default LogStakesHistory;
