import React, { useEffect, useState } from "react";
import axios from "axios";
import { AiOutlineArrowRight } from "react-icons/ai";
import {
	BlackContainer,
	Button,
	ButtonContainer,
	Footer,
	SecondFooter,
	Section,
	SectionFinal,
	SecondSectionFinal,
	Title,
	SecondTitle,
	
	
} from "./styles";
import Content from "../Content";
import Stepper from "../Stepper";
import { getResults, reportStakesLog, request } from "../../../../../axios";
import "./mobile.css";


const Container = ({
	title,
	subtitle,
	buttonContent,
	actualStepper,
	setActualStepper,
	setUserValue,
	userValue,
	actualPlan,
	setActualPlan,
	setActualPeriod,
	actualPeriod,
}) => {
	const [resultsRes, setResultsRes] = useState();


	
			
	const getUserTokenChangePassword =  () => {
		const headers = {
			"content-type": "application/json",
		};
		const params = {
			action: "get-token",
		};
		const data = {
			email: this.state.email,
		};
	
		const url = `${process.env.REACT_APP_API_URL}/api/v1/reset-password/`;
	
		this.setState({ ...this, loading: true });
		axios
			.post(url, data, { headers, params })
			.then(() => {
				alert('strings.reset_password_success_message');
				window.location.reload();
			})
			.catch((error) => {
				this.setState({ ...this, loading: false });
	
				if (error.response) {
					this.setState({ ...this, showModal: false });
					alert(error.response.data.error);
				}
			});
	}

	useEffect(() => {
		getResults().then((response) => {
			const results = response.results.map((result) => {
				const resultDetails = JSON.parse(result.details);
				return { ...result, details: resultDetails };
			});

			setResultsRes(results);
		});
	}, []);

	const checkClickAlreadyTen = () => {
		const today_date = new Date(Date.now()).toLocaleString().split(',')[0]
	
		let stored_date = window.localStorage.getItem(
			"date_of_click"
		);
		
		let stored_click = window.localStorage.getItem(
			"stake_simulator_click"
		);

		if (stored_date == null) {
			stored_date = today_date
		}

		if (stored_click == null) {
			stored_date = 1
		} else {
			stored_click = parseInt(stored_click)
			if (stored_date != today_date){
				stored_click = 1
			}
		}

		window.localStorage.setItem(
			"stake_simulator_click",
			stored_click += 1 
		);

		window.localStorage.setItem(
			"date_of_click",
			today_date
		);
		
		if (stored_click > 10)
			return true
			
		return false
	}



	useEffect(() => {
		if (actualStepper === 3){
			const planId = resultsRes.find(elem => elem.plan === actualPlan)
			reportStakesLog(userValue, planId.id)
		}

	}, [actualStepper]) 

	if (actualStepper === 3) {
	
		const resultsCalculated = resultsRes?.map((result) => {
			const findPeriodWithValue = result?.details?.find(
				(detail) => detail.period === actualPeriod
			);
			const valueTotal = findPeriodWithValue.value * userValue;

			const resultCalculated = {
				valueTotal: Math.round(valueTotal),
				plan: result.plan,
				link_plan: result.link_plan,
			};
			return resultCalculated;
		});

		const actualPlanResults = resultsCalculated.find(
			({ plan }) => plan === actualPlan
		);

		const otherResults = resultsCalculated.filter(
			(result) => result.plan !== actualPlan
		);
		const formattedNumber = (number) =>
			number
				.toLocaleString("pt-BR", { style: "currency", currency: "BRL" })
				.slice(0, -3);

		if(checkClickAlreadyTen()){

			return (
			
				<div style={{ display: "flex", flexDirection: "column"}}>
					<Title style={{ maxWidth: "626px" }} className="title">
					</Title>
					<SecondSectionFinal>
						<div className="last__section" >
							<h3>O seu ganho foi:</h3>
						</div> 
	
						<div className="last__section__body">
							<span
								className="price__final"
								style={{
									fontSize:
										actualPlanResults.valueTotal.toString().length > 6
											? "63px"
											: "80px",
								}}
							>
								R$ 000 !
							</span>
							<p className="product">
								<span>Produto:</span> 
							</p>
							<p className="product" style={{ marginBottom: "18px" }}>
								<span>Período:</span> 
							</p>
							<a>
								Clique e adquira a sua vaga no {actualPlan}
								<AiOutlineArrowRight
									fontSize={20}
									style={{ paddingLeft: "8px" }}
								/>
							</a>
							<div className="line" />
							<SecondFooter>
								<div style={{ marginBottom: "19px" }}>
									<span style={{ paddingBottom: "19px" }}>
										Resultados de outros produtos no mesmo período:
									</span>
								</div>
	
								{otherResults?.map((result) => {
									return (
										<div>
											<span className="small__font">
												{result?.plan}:
												<span className="green__text small__font">
												R$ 000 !
												</span>
											</span>
										</div>
									);
								})}
							</SecondFooter>							
						</div>
					</SecondSectionFinal>
					<div style={{display:"flex", flexDirection:"column", maxWidth: "950px", width:" 50%", minHeight: "612px", position:"absolute"}}>
						<SecondTitle>
							Você atingiu o seu limite de simulações
						</SecondTitle>
						<ButtonContainer>
						<div  className="last__section__body" >
							<a href={actualPlanResults.link_plan}>
								Clique e adquira seu plano 
								<AiOutlineArrowRight
									fontSize={20}
									style={{ paddingLeft: "8px" }}
								/>
							</a>
						</div>

					</ButtonContainer>
						
						
					</div>
					
					
				</div>
				
			);
		}

		return (
			<div style={{ display: "flex", flexDirection: "column" }}>
				<Title style={{ maxWidth: "626px" }} className="title">
					Simulador de <BlackContainer>ganhos</BlackContainer>
				</Title>
				<SectionFinal>
					<div className="last__section">
						<h3>O seu ganho foi:</h3>
					</div> 

					<div className="last__section__body">
						<span
							className="price__final"
							style={{
								fontSize:
									actualPlanResults.valueTotal.toString().length > 6
										? "63px"
										: "80px",
							}}
						>
							{formattedNumber(actualPlanResults.valueTotal)} !
						</span>
						<p className="product">
							<span>Produto:</span> {actualPlan}{" "}
						</p>
						<p className="product" style={{ marginBottom: "18px" }}>
							<span>Período:</span> {actualPeriod}{" "}
						</p>
						<a href={actualPlanResults.link_plan}>
							Garanta sua vaga no {actualPlan}
							<AiOutlineArrowRight
								fontSize={20}
								style={{ paddingLeft: "8px" }}
							/>
						</a>
						<div className="line" />
						<Footer>
							<div style={{ marginBottom: "19px" }}>
								<span style={{ paddingBottom: "19px" }}>
									Resultados de outros produtos no mesmo período:
								</span>
							</div>

							{otherResults?.map((result) => {
								return (
									<div>
										<span className="small__font">
											{result?.plan}:
											<span className="green__text small__font">
												{formattedNumber(result?.valueTotal)}
											</span>
										</span>
									</div>
								);
							})}
						</Footer>
					</div>
				</SectionFinal>
			</div>
		);
	}

	const isDisabled = () => {
		if (actualStepper === 0) {
			return !userValue;
		}
		if (actualStepper === 1) {
			return !actualPlan;
		}
		if (actualStepper === 2) {
			return !actualPeriod;
		}
		return true;
	};

	return (
		<div>
			<Title className="title">
				Simulador de <BlackContainer>ganhos</BlackContainer>
			</Title>
			<Section>
				<div className="first__section">
					<h3 className="subtitle">{title}</h3>
					<span className="subtitle2">{subtitle}</span>
				</div>
				<Content
					actualStepper={actualStepper}
					setUserValue={setUserValue}
					userValue={userValue}
					setActualPlan={setActualPlan}
					actualPlan={actualPlan}
					setActualPeriod={setActualPeriod}
					actualPeriod={actualPeriod}
					resultsRes={resultsRes}
				/>
			</Section>
			<div className="d-flex justify-content-center" style={{ width: "100%" }}>
				<Button
					disabled={isDisabled()}
					onClick={() => setActualStepper((old) => old + 1)}
				>
					<span>{buttonContent}</span>
					<AiOutlineArrowRight style={{ paddingLeft: "8px" }} />
				</Button>
			</div>

			<Stepper
				actualStepper={actualStepper}
				setActualStepper={setActualStepper}
			/>
		</div>
	);
};

export default Container;
