import React from "react";

export default (props) => {
	return (
		<div className="container">
			<div className="row">
				<div className="col-md-12 mb-2">
					<h3 className="text-white">{props.title}</h3>
				</div>
			</div>
			{props.children}
		</div>
	);
};
