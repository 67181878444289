import { yupResolver } from "@hookform/resolvers/yup";
import React, { useState } from "react";
import { Button } from "react-bootstrap";
import { useForm } from "react-hook-form";
import InputMask from "react-input-mask";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import * as yup from "yup";
import { register as RegisterAPI } from "../../../../../axios";
import translate from "../../../../../translate";
import { FormGroup } from "../FormGroup";
import { FormGroupPassword } from "../FormGroupPassword";
import styles from "./styles.module.css";
const { pages } = translate();
const strings = pages["register"];

const FormRegister = () => {
	const [isLoading, setIsLoading] = useState(false);
	const navigate = useNavigate();

	const formSchema = yup.object().shape({
		name: yup
			.string()
			.required("Este campo é obrigatório")
			.min(7, "O nome deve ter no mínimo 7 caracteres"),
		email: yup
			.string()
			.required("Este campo é obrigatório")
			.email("E-mail inválido"),
		cpf: yup.string().required("Este campo é obrigatório"),
		password: yup
			.string()
			.required("Este campo é obrigatório.")
			.min(6, "A senha deve ter no mínimo 6 caracteres"),
		passwordConfirmation: yup
			.string()
			.required("Este campo é obrigatório.")
			.oneOf([yup.ref("password")], "Os campos não coincidem"),
		phone: yup.string().required("Este campo é obrigatório"),
	});
	const {
		register,
		handleSubmit,
		formState: { errors },
	} = useForm({ resolver: yupResolver(formSchema) });

	const registerUser = async (data) => {
		setIsLoading(true);
		const cpfFormatted = data.cpf.replace(/\D+/g, "");
		const phoneFormatted = data.phone.replace(/\D+/g, "");
		const dataFormatted = {
			...data,
			cpf: cpfFormatted,
			phone: phoneFormatted,
		};
		RegisterAPI(dataFormatted)
			.then((res) => {
				console.log(res);
				setIsLoading(false);
				window.location.href = "/home";
			})
			.catch((error) => {
				setTimeout(() => {
					setIsLoading(false);
				}, 200);
				const toastId = "register-errror";
				let toasMessage = strings.register_form.errors.register_error;
				if (error.response) {
					const { status, data } = error.response;
					if (status === 400) {
						if (data.cpf_registred_error) {
							toasMessage = strings.register_form.errors.cpf_registred_error;
						} else if (data.phone_number_registred_error) {
							toasMessage =
								strings.register_form.errors.phone_number_registred_error;
						} else if (data.email) {
							toasMessage = strings.register_form.errors.email;
						}
					}
				}
				toast(toasMessage, {
					type: "error",
					toastId,
					delay: 4000,
				});
			});
	};

	const errorCpf = !!errors?.cpf?.message ? "input__error" : "";
	const errorTel = !!errors?.phone?.message ? "input__error" : "";

	return (
		<div className={styles.panel__register}>
			<h3 className={styles.register__title}>Cadastre-se</h3>
			<form
				onSubmit={handleSubmit(registerUser)}
				className={styles.container__form}
			>
				<FormGroup
					errors={errors?.name?.message}
					register={register}
					registerName="name"
					placeholder="Nome completo"
				/>
				<FormGroup
					errors={errors?.email?.message}
					register={register}
					registerName="email"
					placeholder="E-mail"
				/>
				<div>
					<InputMask
						mask="(99) 99999-9999"
						placeholder="Telefone"
						className={`${styles.react__input__mask} form-control ${styles[errorTel]}`}
						type="text"
						{...register("phone")}
						style={{
							color: "white",
							fontFamily: "Bai Jamjuree",
							backgroundColor: "rgba(64, 64, 64, 0.6)",
							fontWeight: 700,
						}}
					/>
					{!!errors?.phone?.message && (
						<p className={styles.error__message}> {errors?.phone.message}</p>
					)}
				</div>

				<div>
					<InputMask
						mask="999.999.999-99"
						placeholder="CPF"
						className={`${styles.react__input__mask} form-control ${styles[errorCpf]}`}
						type="text"
						style={{
							color: "white",
							fontFamily: "Bai Jamjuree",
							backgroundColor: "rgba(64, 64, 64, 0.6)",
							fontWeight: 700,
						}}
						{...register("cpf")}
					/>
					{!!errors?.cpf?.message && (
						<p className={styles.error__message}> {errors?.cpf.message}</p>
					)}
				</div>

				<FormGroupPassword
					register={register}
					registerName="password"
					placeholder="Senha"
					color="text-white"
					errors={errors?.password?.message}
				/>
				<FormGroupPassword
					errors={errors?.passwordConfirmation?.message}
					registerName="passwordConfirmation"
					register={register}
					placeholder="Confirme sua senha"
					color="text-white"
				/>

				<Button
					type="submit"
					// disabled={!this.state.formIsValid || this.state.loadind}
					className="w-100 btn-login mt-4"
				>
					{isLoading ? (
						<React.Fragment>
							{strings.register_form.register_button_field_label_loading}{" "}
							<i className="fas fa-futbol text-dark fa-spin"></i>
						</React.Fragment>
					) : (
						strings.register_form.register_button_field_label
					)}
				</Button>
			</form>
		</div>
	);
};

export default FormRegister;
