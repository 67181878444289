import styled from "styled-components";

export const Step = styled.div`
	width: ${(props) => (props.isActual ? "55px" : "17px")};
	height: 17px;
	border-radius: 25px;
	background-color: #d2ff00;
	cursor: pointer;
`;

export const ContainerStepper = styled.div`
	gap: 32px;
	display: flex;
	align-items: center;
	justify-content: center;
	margin-top: 18px;
	max-width: 950px;
`;
