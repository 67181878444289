import React from "react";
import axios from "axios";
import { Button, FormControl, Modal } from "react-bootstrap";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";

import "./style.css";
import "./style.mobile.css";

import { login, me, setRedis } from "../../../axios";
import translate from "../../../translate";
import PasswordInput from "../../../components/form/PasswordInput";
import { publicIpv4 } from "public-ip";
import { RiCheckboxLine, RiCheckboxBlankLine } from "react-icons/ri";

import logotipo from "../../../assets/images/logotipo.svg";

const { pages } = translate();
const strings = pages["login"];

export default class Login extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			showModal: false,
			isAllowed: false,
			loading: false,
			email: "",
			password: "",
			forgotPassword: { password: "" },
			onFocusInput: {
				email: false,
				password: false,
			},
			checkRememberLogin: false,
		};
		this.onChange = this.onChange.bind(this);
		this.onLogin = this.onLogin.bind(this);
		this.onOpenModal = this.onOpenModal.bind(this);
		this.onCloseModal = this.onCloseModal.bind(this);
		this.getUserTokenChangePassword =
			this.getUserTokenChangePassword.bind(this);
		this.onChangeForgotPassword = this.onChangeForgotPassword.bind(this);
		this.onForgot = this.onForgot.bind(this);
	}

	onChange(e) {
		const { name: key, value } = e.target;
		const { state } = this;
		state[key] = value;
		this.setState(state);
	}

	onOpenModal() {
		this.setState({ ...this, showModal: true });
	}
	onCloseModal() {
		this.setState({ ...this, showModal: false });
	}

	async onLogin(e) {
		e.preventDefault();
		const { email, password } = this.state;
		const data = { email, password };
		const ip = await publicIpv4();
		this.setState({ ...this, loading: true });
		await login(data)
			.then((data) => {
				me().then((data) => {
					window.localStorage.setItem("user", data.id);
					if (data.role) {
						const key = window.localStorage.getItem(
							process.env.REACT_APP_SITE_STORAGE_KEY_API_KEY
						);
						window.localStorage.setItem(
							process.env.REACT_APP_SITE_STORAGE_KEY_USER_ROLE,
							key + "|" + data.id
						);
					} else {
						setRedis({ key: `${data.email}|${ip}`, value: data.id.toString() });
					}
					setTimeout(() => {
						window.location.href = "/";
					}, 500);
				});
			})
			.catch((error) => {
				setTimeout(() => {
					this.setState({ ...this, loading: false });
				}, 200);

				if (error.response) {
					const { status, data } = error.response;

					if (status === 401) {
						if (data.detail) {
						}
					}
				}
				toast.error(strings.errors.login_fail, {
					toastId: "error-login",
					position: "top-left",
				});
			});
		this.setState({ ...this, loading: false });
	}

	getUserTokenChangePassword() {
		const headers = {
			"content-type": "application/json",
		};
		const params = {
			action: "get-token",
		};
		const data = {
			email: this.state.email,
		};

		const url = `${process.env.REACT_APP_API_URL}/api/v1/reset-password/`;

		this.setState({ ...this, loading: true });
		axios
			.post(url, data, { headers, params })
			.then(() => {
				alert(strings.reset_password_success_message);
				window.location.reload();
			})
			.catch((error) => {
				this.setState({ ...this, loading: false });

				if (error.response) {
					this.setState({ ...this, showModal: false });
					alert(error.response.data.error);
				}
			});
	}

	onForgot() {
		this.setState({ ...this, loading: true });
		const { forgotPassword: data } = this.state;
		const headers = { "content-type": "application/json" };
		const params = { action: "reset-password" };

		const url = `${process.env.REACT_APP_API_URL}/api/v1/reset-password/`;

		axios
			.post(url, data, { headers, params })
			.then(() => {
				alert(strings.reset_password_success_change);
				window.location.href = "/";
			})
			.catch((error) => {
				if (error.response) {
					this.setState({ ...this, loading: false });

					if (error.response) {
						this.setState({ ...this, forgotPassword: {} });
						alert(error.response.data.error);
					}
				}
			});
	}

	onChangeForgotPassword(e) {
		const { name: key, value } = e.target;
		const { forgotPassword } = this.state;
		forgotPassword[key] = value;
		this.setState({ ...this, forgotPassword });
	}

	componentDidMount() {
		document.title = "Login";
		if (window.location.search.includes("reset-password=")) {
			const token = window.location.search.split("=")[1];
			const forgotPassword = {
				showModal: true,
				token,
			};
			this.setState({ ...this, forgotPassword });
		}
		document.body.classList.add("body__overflow__hidden");
	}

	componentWillUnmount() {
		document.body.classList.remove("body__overflow__hidden");
	}

	render() {
		return (
			<div className="bg-primary">
				<div className="container-fluid login-page text-white">
					<div className="row">
						<div className="col-12 logo-image-col"></div>
					</div>
					<div className="row">
						<div className="col-md-5  login-container mt-5">
							<div className="container-logo__login">
								<img src={logotipo} className="logotipo" alt="logotipo" />
							</div>
							<div className="panel">
								<span className="login__text">
									Faça login para acessar sua conta
								</span>
								<form action="#!" className="ml-3 mt-3" onSubmit={this.onLogin}>
									<div
										className=""
										style={{ position: "relative", marginBottom: "12px" }}
									>
										<FormControl
											type="email"
											value={this.state.email}
											autoFocus
											required
											className="input__form"
											disabled={this.state.loading}
											name="email"
											onChange={this.onChange}
											autoComplete="true"
										/>
										<div className="input__container-form">
											<i className={`fa-regular fa-envelope `}></i>
										</div>
									</div>
									<div className="" style={{ position: "relative" }}>
										<PasswordInput
											required
											name="password"
											value={this.state.password}
											disabled={this.state.loading}
											withIcon
											onChange={this.onChange}
										/>
									</div>
									<div
										className="form-footer login__text--normal"
										style={{ display: "flex", alignItems: "center" }}
									>
										<div>
											<button
												type="button"
												className="remember-login__button"
												onClick={() => {
													if (this.state.checkRememberLogin) {
														this.setState({
															...this,
															checkRememberLogin: false,
														});
													} else {
														this.setState({
															...this,
															checkRememberLogin: true,
														});
													}
												}}
											>
												{this.state.checkRememberLogin ? (
													<RiCheckboxLine size={20} />
												) : (
													<RiCheckboxBlankLine size={20} />
												)}
												{/* <input
													disabled={this.state.loading}
													type="checkbox"
													id="save__email"
												/>{" "} */}
												<label
													htmlFor="save__email"
													className="save__email__label"
												>
													{strings.save_email_label}
												</label>
											</button>
										</div>
									</div>
									<button
										disabled={this.state.loading}
										type="submit"
										className="btn btn-primary w-100 btn-login mt-2"
									>
										<span>{strings.button_login_label}</span>{" "}
										{this.state.loading ? (
											<i className="fas fa-futbol text-white fa-spin"></i>
										) : (
											<span />
										)}
									</button>
									<p
										className="text-center mt-2 login__text--normal"
										style={{ fontWeight: 500 }}
									>
										{strings.create_account_label}{" "}
										<Link
											to="/register"
											className="cursor-pointer login__text--green"
										>
											{strings.create_account_link_label}
										</Link>
									</p>
									<div>
										<p
											onClick={this.onOpenModal}
											className="cursor-pointer text-center mt-2 login__text--normal"
											style={{ color: "#D2FF00", fontWeight: 700 }}
										>
											{strings.forgot_password_label}
										</p>
									</div>
								</form>
							</div>
						</div>
					</div>
					<Modal show={this.state.showModal} onHide={this.onCloseModal}>
						<Modal.Header closeButton closeVariant="primary">
							<Modal.Title>{strings.forgot_password_modal_title}</Modal.Title>
						</Modal.Header>
						<Modal.Body>
							<p className="lead">{strings.forgot_password_modal_subtitle}</p>
							<FormControl
								type="email"
								autoFocus
								required
								name="email"
								value={this.state.email}
								onChange={this.onChange}
								autoComplete
							/>
							<div className="forgot-password-button-div mt-2">
								<Button
									disabled={this.state.loading}
									onClick={this.getUserTokenChangePassword}
								>
									{strings.forgot_password_modal_button_title}
								</Button>
							</div>
						</Modal.Body>
					</Modal>
					<Modal show={this.state.forgotPassword.showModal}>
						<Modal.Header>{strings.reset_password_modal_label}</Modal.Header>
						<Modal.Body>
							<div className="form-group">
								<div className="form-label">
									{strings.reset_password_modal_password_label}
								</div>
								<PasswordInput
									name="password"
									type="password"
									disabled={this.state.loading}
									onChange={this.onChangeForgotPassword}
									value={this.state.forgotPassword.password}
								/>
							</div>
							<div className="form-group">
								<div className="form-label">
									{strings.reset_password_modal_confirm_password_label}
								</div>
								<PasswordInput
									type="password"
									name="password2"
									disabled={this.state.loading}
									onChange={this.onChangeForgotPassword}
									value={this.state.forgotPassword.password2}
								/>
							</div>
						</Modal.Body>
						<Modal.Footer>
							<Button
								onClick={this.onForgot}
								disabled={
									!this.state.forgotPassword.password ||
									this.state.forgotPassword.password !==
										this.state.forgotPassword.password2 ||
									this.state.loading
								}
							>
								{strings.reset_password_modal_button_label}
							</Button>
						</Modal.Footer>
					</Modal>
				</div>
			</div>
		);
	}
}
