import React from "react";
import styled from "styled-components";

const TutorialBox = styled.div`
    margin-bottom: 20px;
`
const TutorialInnerContent = styled.div`
    padding: 10px;
`
const TutorialTitle = styled.h3``

const TutorialFeatureImage = styled.img`
    border-radius: 5px;
    max-height: 200px;
    min-height: 100px;
`
const TutorialIconsFooter = styled.div`
    display: flex;
    flex-direction:row;
    justify-content: space-between;
    padding-left: 20px;
    padding-right: 20px;
`
const IconButton = styled.i`
    cursor: pointer;
`

export default props => <TutorialBox
    className="col-md-4 col-xs-12 mx-auto"
    id={props.item.id}
    key={props.item.id}>
    <TutorialInnerContent>
        <TutorialTitle>
            {props.item.title}
        </TutorialTitle>
        <TutorialFeatureImage
            className="img-fluid"
            src={props.item.feature_image_url}
            alt={props.item.title} />
    </TutorialInnerContent>
    <TutorialIconsFooter className={`${props.showFooter ? "" : "d-none"}`}>
        <IconButton
            onClick={() => props.editItem(props.item)}
            className="fas fa-eye text-primary" />

        <IconButton
            onClick={() => props.openModalYtEmbed(props.item)}
            className="fas fa-play text-danger" />

        <IconButton
            onClick={() => this.openModalDelete(props.item)}
            className="fas fa-trash-alt text-danger" />
    </TutorialIconsFooter>
</TutorialBox >