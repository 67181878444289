import React, { useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import { createResults, updateResults } from "../../../../../axios";
import { toast } from "react-toastify";
import DetailsFormPart from "./DetailsFormPart";

const ResultForm = ({
	setIsOpen,
	defaultValues,
	setDefaultValues,
	setResultsRes,
}) => {
	const [details, setDetails] = useState([
		{
			period: "2023 (até junho)",
			value: 0,
		},
		{
			period: "2022 - 2023",
			value: 0,
		},

		{
			period: "2021 - 2023",
			value: 0,
		},
	]);
	const [plan, setPlan] = useState("");
	const [link, setLink] = useState("");
	useEffect(() => {
		if (defaultValues) {
			setPlan(defaultValues.plan);
			const detailsThreated = defaultValues.details.map((detail) => {
				return {
					period: detail.period,
					value: +detail.value,
				};
			});
			setDetails(detailsThreated);
			setLink(defaultValues.link_plan);
		}
	}, [defaultValues]);

	const onSubmit = async (e) => {
		if (!plan || !link) {
			toast.error("Preencha todos os campos!");
		}
		e.preventDefault();
		const data = {
			plan,
			details: JSON.stringify(details),
			link_plan: link,
		};
		toast.loading("Carregando...", {
			toastId: "loading",
		});
		if (defaultValues) {
			await updateResults(defaultValues.id, data).then((res) => {
				toast.success("Resultado atualizado com sucesso!");
				setIsOpen(false);
				setDetails([
					{
						period: "2023 (até junho)",
						value: 0,
					},
					{
						period: "2022 - 2023",
						value: 0,
					},

					{
						period: "2021 - 2023",
						value: 0,
					},
				]);
				setPlan("");
				setResultsRes((old) => {
					const newRes = [...old];
					const indexActual = newRes.findIndex(
						(response) => response.id === defaultValues.id
					);
					newRes[indexActual] = res;

					return newRes;
				});
				setDefaultValues(undefined);
			});
		} else {
			await createResults(data).then((res) => {
				toast.success("Resultado criado com sucesso!");
				setIsOpen(false);
				setDetails([
					{
						period: "2023 (até junho)",
						value: 0,
					},
					{
						period: "2022 - 2023",
						value: 0,
					},

					{
						period: "2021 - 2023",
						value: 0,
					},
				]);
				setResultsRes((old) => {
					return [...old, res];
				});
				setPlan("");
			});
		}
		toast.dismiss("loading");
	};

	return (
		<div className="container p-3">
			<form onSubmit={(e) => onSubmit(e)}>
				<div className="mb-3">
					<label for="plan" class="form-label text-white">
						Nome do plano
					</label>
					<input
						id="plan"
						className="form-control select__adm"
						value={plan}
						onChange={(e) => setPlan(e.target.value)}
					/>

					<label for="link" class="form-label text-white">
						Link
					</label>
					<input
						id="link"
						className="form-control select__adm"
						value={link}
						onChange={(e) => setLink(e.target.value)}
					/>

					{details?.map((_, i) => {
						return (
							<DetailsFormPart
								key={i}
								setDetails={setDetails}
								details={details}
								index={i}
							/>
						);
					})}
				</div>
				<div className="d-flex justify-content-end ">
					<Button type="submit">Enviar</Button>
				</div>
			</form>
		</div>
	);
};

export default ResultForm;
