import React, { useEffect, useState } from "react";
import { BrowserRouter, Routes, Route, Link } from "react-router-dom";
import DashboardImage from "../../assets/images/user.svg";
import ProductsImage from "../../assets/images/campo-de-futebol.svg";
import { LogoImg, MainContent } from "./components";
import { deleteRedis, getRedisItems } from "../../axios";
import Home from "./home";
import ViewProduct from "./product/ViewProduct";
import Product from "./product";
import { Button } from "react-bootstrap";
import { TbSoccerField } from "react-icons/tb";
import { AiOutlineUser } from "react-icons/ai";
import "./mobile.styles.css";
import "./style.scss";

export default class Dashboard extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			page: window.location.pathname,
			loading: false,
			mobile: false,
			key: "",
		};
		this.navigate = this.navigate.bind(this);
	}

	useUnload = (fn) => {
		const cb = React.useRef(fn);

		React.useEffect(() => {
			const onUnload = cb.current;
			window.addEventListener("beforeunload", onUnload);
			return () => {
				window.removeEventListener("beforeunload", onUnload);
			};
		}, [cb]);
	};

	toggleMenu() {
		const menu = document.getElementById("menu");
		const innerPage = document.getElementById("inner-page");
		const sideBar = document.querySelector(".sidebar");
		const iconClose = document.querySelector("#toggle__icon-close");
		const iconToggle = document.querySelector("#toggle__icon-bars");
		if (this.state.mobile) {
			// innerPage.classList.remove("d-none");
			menu.classList.remove("d-block");
			// menu.classList.remove("animate");
			menu.classList.add("d-none");

			iconClose.classList.add("d-none");
			iconClose.classList.remove("d-block");

			iconToggle.classList.add("d-block");
			iconToggle.classList.remove("d-none");

			sideBar.style.width = "auto";

			this.setState({ ...this, mobile: false });
		} else {
			// innerPage.classList.add("d-none");
			menu.classList.remove("d-none");
			menu.classList.add("d-block");

			iconClose.classList.add("d-block");
			iconClose.classList.remove("d-none");

			iconToggle.classList.remove("d-block");
			iconToggle.classList.add("d-none");

			// menu.classList.add("animate");
			sideBar.style.width = "100px";
			sideBar.style.zIndex = 999;
			this.setState({ ...this, mobile: true });
		}
	}

	navigate(e) {
		if (this.state.mobile) {
			this.toggleMenu();
		}

		setTimeout(() => {
			this.loadpage();
			this.setState({ ...this, page: window.location.pathname });
			console.log(this.state.page);
		}, 100);
	}
	beforeUnload(e) {
		e.preventDefault();
		e.returnValue = "Deseja sair da página?";
	}

	componentWillUnmount() {
		window.removeEventListener("beforeunload", this.beforeUnload, false);
	}

	async loadpage() {
		const token = window.localStorage.getItem(
			process.env.REACT_APP_SITE_STORAGE_KEY_API_KEY
		);
		const userId = window.localStorage.getItem("user");
		const redisUsers = await getRedisItems();
		const key = redisUsers
			? Object.keys(redisUsers)
					.find((k) => redisUsers[k] === userId)
					?.toString()
			: "-";
		if (key !== "-") {
			this.setState({
				...this,
				key: key || "-",
			});
		}
		const pathname = window.location.pathname;
		window.addEventListener("beforeunload", this.beforeUnload, false);
		if (!token || pathname.includes("/logout") || pathname.includes("/login")) {
			try {
				window.localStorage.clear();
				await deleteRedis({ key: key });
			} catch (e) {
				window.localStorage.clear();
			} finally {
				setTimeout(() => {
					window.location.href = "/login";
				}, 1700);
			}
		}
	}

	inactivityTime() {
		let time;
		window.onload = resetTimer;
		document.onmousemove = resetTimer;
		document.onkeyup = resetTimer;
		document.onkeypress = resetTimer;

		async function logout() {
			try {
				window.localStorage.clear();
				await deleteRedis({ key: this.state.key });
			} catch (e) {
				window.localStorage.clear();
			} finally {
				setTimeout(() => {
					window.location.href = "/login";
				}, 1700);
			}
		}
		function resetTimer() {
			clearTimeout(time);
			time = setTimeout(logout, 10 * 60 * 1000); // 10 minutes
		}
	}

	render() {
		return (
			<BrowserRouter>
				<MainContent>
					<div style={{ display: "flex", minHeight: "100vh" }}>
						<div className="sidebar">
							<div
								className="d-none d-md-block side-menu"
								id="menu"
								style={{ position: "fixed" }}
							>
								<div className="logo-image">
									<LogoImg />
								</div>
								<br />
								<hr
									className="divider"
									style={{ width: "90%", margin: "0 auto" }}
								/>
								<div className="side-menu__total-area">
									<div className="side-menu-area py-1">
										<MenuItem
											onClick={this.navigate}
											path="home"
											title="Minha área"
											img={DashboardImage}
											icon={
												<AiOutlineUser
													size={30}
													fill={
														this.state.page.includes("home")
															? "#5b5b5b"
															: "white"
													}
												/>
											}
											page={this.state.page}
										/>
										<MenuItem
											onClick={this.navigate}
											path="product"
											title="Recomendações"
											img={ProductsImage}
											icon={
												<TbSoccerField
													size={30}
													color={
														this.state.page.includes("product")
															? "#5b5b5b"
															: "white"
													}
												/>
											}
											page={this.state.page}
										/>
									</div>
									<div className="container__button--logout">
										<hr
											className="divider"
											style={{ width: "90%", margin: "0 auto" }}
										/>

										<MenuItemLogout
											onClick={this.navigate}
											className="botao-sair"
										/>
									</div>
								</div>
							</div>
							<Button
								onClick={() => this.toggleMenu()}
								className="abs-top toggle-menu-button"
							>
								<i className="fas fa-bars" id="toggle__icon-bars"></i>
								<i className="fas fa-close d-none" id="toggle__icon-close"></i>
							</Button>
						</div>
						<div className="mt-2 " id="inner-page__container">
							<div className="inner-page">
								<Routes>
									<Route path="/home" element={<Home />} />
									<Route path="/product" element={<Product />} />
									<Route path="/product/view/:id" element={<ViewProduct />} />
									<Route path="*" element={<Home />} />
								</Routes>
							</div>
						</div>
					</div>
				</MainContent>
			</BrowserRouter>
		);
	}
}

function MenuItem(props) {
	const active = props.page.includes(props.path) ? "active__menu" : "";
	const activeTitle = props.page.includes(props.path)
		? "menu__item-text__active"
		: "";
	return (
		<Link
			title={props.title}
			onClick={() => {
				props.onClick();
			}}
			to={`/${props.path}`}
			style={{ textDecoration: "none" }}
		>
			<div className="side-menu-item container__menu-item">
				<div className={`side-menu-item-icon ${active}  py-2`}>
					{props.icon}
					<p
						className={`d-sm-block text-center  menu__item-text ${activeTitle} m-0`}
					>
						{props.title}
					</p>
				</div>
			</div>
		</Link>
	);
}

function MenuItemLogout(props) {
	return (
		<div
			className=" cursor-pointer d-flex justify-content-center align-items-end"
			style={{ paddingTop: "25px" }}
		>
			<Link title="Sair" onClick={props.onClick} to="/logout">
				<i className="text-white fa-2-1x fa-solid fa-right-from-bracket"></i>
			</Link>
		</div>
	);
}
