import styled from "styled-components";

export const Title = styled.h1`
	max-width: 950px;

	font-family: Poppins;
	font-size: 58px;
	font-weight: 600;
	line-height: 96px;
	letter-spacing: -0.06em;
	text-align: center;
	color: white;
	margin-bottom: 20px;
	
`;

export const SecondTitle = styled.h1`
	max-width: 950px;
	width: 90%;
	font-family: Poppins;
	font-size: 50px;
	font-weight: 600;
	line-height: 96px;
	letter-spacing: -0.06em;
	text-align: center;
	color: white;
	margin-bottom: 20px;
	margin-top: 20%;
	

`;

export const BlackContainer = styled.span`
	color: black;
	border-radius: 10px;
	background: #d2ff00;
	width: 240px;
	height: 76px;
`;

export const Section = styled.div`
	max-width: 950px;
	width: 90%;
	min-height: 312px;
	border-radius: 27px;
	background: #282828;
	padding: 14px 19px 28px 19px;
	& > .first__section {
		padding: 4px 0px 12px 35px;
		border-radius: 27px;
		background: #5b5b5b;
		height: 92px;
		& > h3 {
			color: #fff;
			font-size: 25px;

			font-family: Poppins;
			font-style: normal;
			font-weight: 400;
			line-height: normal;
			height: 44px;
		}

		& > span {
			color: #838383;
			font-size: 15px;
			font-family: Poppins;
			font-style: normal;
			font-weight: 400;
			line-height: normal;
		}
	}
`;

export const Button = styled.button`
	border-radius: 27px;
	background: #d2ff00;
	margin-top: 17px;
	width: 60%;
	&:hover {
		opacity: 0.8;
	}
	max-width: 950px;

	height: 60px;
	border: transparent;
`;
export const SecondButton = styled.button`
	border-radius: 27px;
	background: #000;
	margin-top: 17px;
	width: 60%;
	&:hover {
		opacity: 0.8;
	}
	max-width: 950px;

	height: 60px;
	border: transparent;
`;

export const SectionFinal = styled.div`
	max-width: 605px;
	width: 90%;
	border-radius: 27px;
	padding: 12px 14px;
	background: #282828;
	& > .last__section {
		height: 72px;
		border-radius: 27px;
		background: #5b5b5b;
		display: flex;
		align-items: center;
		padding-left: 42px;
		color: white;
		& > h4 {
			color: #fff;
			font-size: 40px;
			margin: 0;
			padding: 0;
			font-family: Poppins;
			font-style: normal;
			font-weight: 400;
			line-height: normal;
		}
	}

	& > .last__section__body {
		padding: 10px 36px;
		padding-bottom: 42px;

		& > .price__final {
			line-height: 106px;
			color: #d2ff00;
			font-size: 96px;
			font-family: Poppins;
			font-style: normal;
			font-weight: 500;
			line-height: normal;
			letter-spacing: -6.24px;
		}
		& > .product {
			color: #d2ff00;
			font-size: 20px;
			font-family: Poppins;
			font-style: normal;
			font-weight: 400;
			line-height: normal;
			padding: 0;
			margin: 0;
			& > span {
				color: #ffffff;
			}
		}
		& > a {
			height: 60px;
			border-radius: 27px;
			background: #d2ff00;
			color: #000;
			font-size: 20px;
			font-family: Poppins;
			font-style: normal;
			font-weight: 400;
			line-height: normal;
			display: flex;
			align-items: center;
			justify-content: center;
			text-decoration: none;
			&:hover {
				opacity: 0.8;
			}
		}
		.line {
			width: 100%;
			margin: 26px 0;
			height: 3px;
			background-color: #5b5b5b;
		}
	}
`;
export const SecondSectionFinal = styled.div`
	max-width: 605px;
	width: 90%;
	border-radius: 27px;
	padding: 12px 14px;
	background: #282828;
	opacity:8%
	& > .last__section {
		height: 72px;
		border-radius: 27px;
		background: #5b5b5b;
		display: flex;
		align-items: center;
		padding-left: 42px;
		color: white;
		opacity:8%
		& > h4 {
			color: #fff;
			font-size: 40px;
			margin: 0;
			padding: 0;
			font-family: Poppins;
			font-style: normal;
			font-weight: 400;
			line-height: normal;
			opacity:8%
		}
	}

	& > .last__section__body {
		padding: 10px 36px;
		padding-bottom: 42px;
		opacity:8%

		& > .price__final {
			line-height: 106px;
			color: #d2ff00;
			font-size: 96px;
			font-family: Poppins;
			font-style: normal;
			font-weight: 500;
			line-height: normal;
			letter-spacing: -6.24px;
			opacity:8%
		}
		& > .product {
			color: #d2ff00;
			font-size: 20px;
			font-family: Poppins;
			font-style: normal;
			font-weight: 400;
			line-height: normal;
			padding: 0;
			margin: 0;
			& > span {
				color: #ffffff;
			}
			opacity:8%
		}
		& > a {
			height: 60px;
			border-radius: 27px;
			background: #000;
			color: #000;
			font-size: 20px;
			font-family: Poppins;
			font-style: normal;
			font-weight: 400;
			line-height: normal;
			display: flex;
			align-items: center;
			justify-content: center;
			text-decoration: none;
			opacity:8%
		}
		.line {
			width: 100%;
			margin: 26px 0;
			height: 3px;
			background-color: #5b5b5b;
			opacity:8%
		}
	}
`;


export const Footer = styled.div`
	span {
		color: #909090;
		font-size: 20px;
		font-family: Poppins;
		font-style: normal;
		font-weight: 400;
		line-height: normal;
	}
	.green__text {
		color: #d2ff00;
		padding-left: 4px;
	}
`;

export const SecondFooter = styled.div`
	span {
		color: #909090;
		font-size: 20px;
		font-family: Poppins;
		font-style: normal;
		font-weight: 400;
		line-height: normal;
		opacity:8%
	}
	.green__text {
		color: #d2ff00;
		padding-left: 4px;
		opacity:8%
	}
`;

export const ButtonContainer = styled.div`
	max-width: 605px;
	width: 90%;
	border-radius: 27px;
	padding: 12px 14px;
	& > .last__section {
		height: 72px;
		border-radius: 27px;
		background: #5b5b5b;
		display: flex;
		align-items: center;
		padding-left: 42px;
		color: white;
		& > h4 {
			color: #fff;
			font-size: 40px;
			margin: 0;
			padding: 0;
			font-family: Poppins;
			font-style: normal;
			font-weight: 400;
			line-height: normal;
		}
	}

	& > .last__section__body {
		padding: 10px 36px;
		padding-bottom: 42px;

		& > .price__final {
			line-height: 106px;
			color: #d2ff00;
			font-size: 96px;
			font-family: Poppins;
			font-style: normal;
			font-weight: 500;
			line-height: normal;
			letter-spacing: -6.24px;
		}
		& > .product {
			color: #d2ff00;
			font-size: 20px;
			font-family: Poppins;
			font-style: normal;
			font-weight: 400;
			line-height: normal;
			padding: 0;
			margin: 0;
			& > span {
				color: #ffffff;
			}
		}
		& > a {
			height: 60px;
			border-radius: 27px;
			background: #d2ff00;
			color: #000;
			font-size: 20px;
			font-family: Poppins;
			font-style: normal;
			font-weight: 400;
			line-height: normal;
			display: flex;
			align-items: center;
			justify-content: center;
			text-decoration: none;
			&:hover {
				opacity: 0.8;
			}
		}
		.line {
			width: 100%;
			margin: 26px 0;
			height: 3px;
			background-color: #5b5b5b;
		}
	}
`;