import React from "react";
import { v4 as uuid } from "uuid";
import styles from "../FormGroup/styles.module.css";

const id = uuid();

export const FormGroupPassword = (props) => {
	const { errors, register, registerName } = props;
	const errorClass = !!errors ? "input__error" : "";

	const [type, setType] = React.useState("password");
	function onChangeType() {
		let newType = "";
		const el = document.getElementById(id);
		if (el.type === "text") {
			newType = "password";
		} else if (el.type === "password") {
			newType = "text";
		}
		setType(newType);
	}
	return (
		<div>
			<div
				style={{
					display: "flex",
				}}
			>
				<input
					type={type}
					required={props.required}
					disabled={props.disabled}
					{...register(registerName)}
					className={`${styles.input__form} ${styles[errorClass]}`}
					placeholder={props.placeholder}
					id={id}
				/>

				<div
					onClick={onChangeType}
					style={{
						display: "flex",
						flexDirection: "row",
						alignItems: "center",
						marginLeft: -30,
						cursor: "pointer",
					}}
				>
					{type === "password" ? (
						<i className={`fa-solid fa-eye ${props.color}`}></i>
					) : (
						<i className={`fa-solid fa-eye-slash ${props.color}`}></i>
					)}
				</div>
			</div>
			{!!errors && <p className={styles.error__message}> {errors}</p>}
		</div>
	);
};
