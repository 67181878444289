import React, { useEffect, useState } from "react";
import ResultForm from "./ResultForm";
import { Button, Modal, OverlayTrigger, Tooltip } from "react-bootstrap";
import { deleteResult, getResults } from "../../../../axios";
import { AiFillDelete, AiFillEdit } from "react-icons/ai";
import { toast } from "react-toastify";

const Results = () => {
	const [resultsRes, setResultsRes] = useState();
	const [defaultValues, setDefaultValues] = useState();
	const [actualId, setActualId] = useState();
	const [isOpen, setIsOpen] = useState(false);
	const [isOpenAlert, setIsOpenAlert] = useState(false);

	useEffect(() => {
		getResults().then((response) => {
			setResultsRes(response.results);
			console.log(response.data, "s");
		});
	}, []);

	const deleteResultAction = async () => {
		toast.loading("Deletando...", {
			toastId: "delete",
		});
		await deleteResult(actualId).then((res) => {
			setResultsRes((old) => {
				return old.filter((res) => res.id !== actualId);
			});
			toast.success("Resultado deletado com sucesso!");
		});
		toast.dismiss("delete");
		setIsOpenAlert(false);
	};

	return (
		<div>
			<div
				style={{
					background: "rgba(64, 64, 64, 1)",
					borderRadius: "8px",
					padding: "32px",
				}}
			>
				<div className="d-flex justify-content-between align-items-center mb-3">
					<h3 className="text-white">Resultados</h3>
					<button className="btn btn-primary" onClick={() => setIsOpen(true)}>
						+ Resultado
					</button>
				</div>
				<table class="table list-users-table">
					<thead>
						<tr>
							<th scope="col">Plano</th>
							<th scope="col">Período - Unidades</th>
							<th scope="col">Link</th>
							<th scope="col">Ações</th>
						</tr>
					</thead>
					<tbody>
						{resultsRes?.map((result, index) => {
							console.log(result.details);
							const resultDetails = JSON.parse(result.details);
							const defaultValues = {
								details: resultDetails,
								plan: result.plan,
								id: result.id,
								link_plan: result.link_plan,
							};
							return (
								<tr key={index}>
									<td className="text-center ">
										<strong
											style={{
												display: "flex",
												height: "100%",
												alignItems: "center",
												justifyContent: "center",
											}}
										>
											{result.plan}
										</strong>
									</td>
									<td>
										{resultDetails.map((detail, index) => {
											return (
												<p key={index}>
													{detail.period} - {detail.value} unidades
												</p>
											);
										})}
									</td>
									<td>
										<OverlayTrigger
											overlay={
												<Tooltip id="tooltip">{result?.link_plan}</Tooltip>
											}
											placement="top"
										>
											<a style={{ color: "white" }} href={result?.link_plan}>
												Link
											</a>
										</OverlayTrigger>
									</td>
									<td>
										<button
											onClick={() => {
												setDefaultValues(defaultValues);
												setIsOpen(true);
											}}
											style={{
												border: "none",
												background: "transparent",
												color: "white",
											}}
										>
											<AiFillEdit />
										</button>
										<button
											style={{
												border: "none",
												background: "transparent",
												color: "white",
											}}
											onClick={() => {
												setIsOpenAlert(true);
												setActualId(result.id);
											}}
										>
											<AiFillDelete />
										</button>
									</td>
								</tr>
							);
						})}
					</tbody>
				</table>
				<Modal show={isOpen} keyboard={false}>
					<div style={{ background: "rgb(64, 64, 64)" }}>
						<Modal.Body>
							<Button
								onClick={() => {
									setIsOpen(false);
									setDefaultValues(undefined);
								}}
								style={{ position: "absolute", top: 10, right: 10 }}
							>
								X
							</Button>
							<ResultForm
								setIsOpen={setIsOpen}
								defaultValues={defaultValues}
								setDefaultValues={setDefaultValues}
								setResultsRes={setResultsRes}
							/>
						</Modal.Body>
					</div>
				</Modal>
				<Modal show={isOpenAlert} onHide={() => setIsOpenAlert(false)}>
					<div style={{ background: "rgb(64, 64, 64)" }}>
						<Modal.Header closeButton className="text-white">
							Deseja remover?
						</Modal.Header>
						<Modal.Body>
							<Button onClick={() => deleteResultAction()} variant="danger">
								Remover
							</Button>
						</Modal.Body>
					</div>
				</Modal>
			</div>
		</div>
	);
};

export default Results;
