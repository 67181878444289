import React from "react";
import { Link } from "react-router-dom";

import logotipo from "../../../assets/images/logotipo.svg";
import "./style.css";
import "./style.mobile.css";

import translate from "../../../translate";
import FormRegister from "./components/FormRegister";

const { pages } = translate();
const strings = pages["register"];

export default class Register extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			formIsValid: false,
			data: {},
			loadind: false,
			show: "",
		};
		this.onSubmitForm = this.onSubmitForm.bind(this);
		this.onChange = this.onChange.bind(this);
		this.showHideEye = this.showHideEye.bind(this);
	}

	onChange(e, b, c) {
		let { name: key, value } = e.target;
		const { data } = this.state;
		if (key === "name") {
			value = value.replace(/\d+/g, "");
		} else if (key === "phone" || key === "cpf") {
			value = value.replace(/\D+/g, "");
		}
		data[key] = value;
		this.setState({ ...this, data });
		setTimeout(() => {
			if (key === "name") {
				this.validateEmptAndMinLength(key, 7);
			} else if (key === "phone") {
				this.validateEmptAndMinLength("name", 7);
				this.validateEmptAndMinLength(key, 9);
			} else if (key === "cpf") {
				this.validateEmptAndMinLength("name", 7);
				this.validateEmptAndMinLength("phone", 9);
				this.validateEmptAndMinLength(key, 11);
			} else if (key === "email") {
				this.validateEmptAndMinLength("name", 7);
				this.validateEmptAndMinLength("phone", 9);
				this.validateEmptAndMinLength("cpf", 9);
				this.validateEmptAndMinLength(key, 10);
			} else if (key === "password") {
				this.validateEmptAndMinLength("name", 7);
				this.validateEmptAndMinLength("phone", 9);
				this.validateEmptAndMinLength("cpf", 9);
				this.validateEmptAndMinLength("email", 10);
				this.validateEmptAndMinLength(key, 5);
			} else if (key === "confirmPassword") {
				this.validateEmptAndMinLength("name", 7);
				this.validateEmptAndMinLength("phone", 9);
				this.validateEmptAndMinLength("cpf", 9);
				this.validateEmptAndMinLength("email", 10);
				this.validateEmptAndMinLength("password", 5);
				this.validateEmptAndMinLength(key, 5);
				this.isValid();
			}
		}, 50);
	}

	showHideEye(e) {
		const { target } = e.target.dataset;
		let { show } = this.state;
		if (target === show) {
			document.querySelector(`[name="${target}"]`).type = "password";
			show = "";
		} else {
			show = target;
			document.querySelector(`[name="${target}"]`).type = "text";
		}
		this.setState({ ...this, show });
	}

	validateEmptAndMinLength(key, length = 5) {
		const { data } = this.state;
		if (!data[key]) {
			try {
				document.querySelector(`[name="${key}"]`).classList.add("is-invalid");
			} catch (error) {}
		} else {
			if (data[key].length < length) {
				try {
					document.querySelector(`[name="${key}"]`).classList.add("is-invalid");
				} catch (error) {}
			} else {
				document
					.querySelector(`[name="${key}"]`)
					.classList.remove("is-invalid");
			}
		}
	}

	isValid() {
		const hasErrors = document.querySelectorAll("is-invalid");
		this.setState({ ...this, formIsValid: hasErrors.length === 0 });
		return hasErrors.length === 0;
	}

	onSubmitForm(e) {
		e.preventDefault();
		const { data } = this.state;
		console.log(data);
		this.setState({ ...this, loadind: true });
	}

	componentDidMount() {
		document.title = strings.title;
	}

	render() {
		return (
			<React.Fragment>
				<div className="container-fluid bg-primary pt-3 main-container register-container">
					{/* <div className="row">
						<div className="col-12 register-logo-img-col">
							<Link className="text-white" to="/">
								<img className="register-logo-img" src={logotipo} />
							</Link>
						</div>
					</div> */}
					<div className="row">
						<div className="col-md-5 col-xs-12 text-section">
							<div className="text-area text-area__shadow">
								<h1 className="text-white">{strings.description}</h1>
								<p className="text-white">
									{strings.call_to_action} &nbsp;&nbsp;{" "}
									<i className="fa-solid fa-arrow-down-long"></i>{" "}
									<i className="fa-solid fa-right-long"></i>
								</p>
							</div>
						</div>
						<div className={`col-md-6 d-flex register__container`}>
							<div className="logo-form__register">
								<Link className="text-white" to="/">
									<img className="register-logo-img" src={logotipo} />
								</Link>
								<FormRegister />
							</div>
						</div>
					</div>
				</div>
			</React.Fragment>
		);
	}
}
