import styled from "styled-components";

export const ContentContainer1 = styled.div`
	min-height: 154px;
	margin-top: 24px;
	width: 100%;
	border-radius: 27px;
	background: #5b5b5b;
	@keyframes slide-in {
		from {
			opacity: 0;
			transform: translateX(100%);
		}
		to {
			opacity: 1;
			transform: translateX(0%);
		}
	}

	.slide-animation {
		animation: slide-in 0.3s ease-in;
	}

	& > div {
		padding: 25px 24px 24px 24px;
		display: flex;
		gap: 10px;
		& > label {
			color: #d2ff00;
			font-size: 40px;
			font-family: Poppins;
			font-style: normal;
			font-weight: 400;
			line-height: normal;
		}
		& > input {
			height: 63px;
			border-radius: 27px;
			width: 100%;
			font-family: Poppins;
			font-size: 15px;
			font-weight: 400;
			line-height: 23px;
			letter-spacing: 0em;
			text-align: left;
			color: white;
			background-color: #282828;
			border: transparent;
			padding: 0 31px;
			&:placeholder {
				font-family: Poppins;
				font-size: 15px;
				font-weight: 400;
				line-height: 23px;
				letter-spacing: 0em;
				text-align: left;
				color: #838383;
			}
			&:focus {
				/* color: black; */
				/* background-color: white; */
				outline: none;
				/* font-weight: 700; */

				border-color: #d2ff00;
				box-shadow: 0 0 5px rgba(210, 255, 0, 0.4);
			}
			&::-webkit-outer-spin-button,
			&::-webkit-inner-spin-button {
				-webkit-appearance: none;
				margin: 0;
			}

			/* Firefox */
			&[type="number"] {
				-moz-appearance: textfield;
			}
			&:-webkit-autofill,
			&:-webkit-autofill:hover,
			&:-webkit-autofill:focus,
			&:-webkit-autofill:active {
				-webkit-box-shadow: 0 0 0 30px #24232b inset !important;
				-webkit-text-fill-color: #ffffff !important;
			}
		}
	}
`;
