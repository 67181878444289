import React from "react";
import { Link } from "react-router-dom";

import "./styles.css";

import { getUserContract } from "../../../axios";

import Page from "../components/Page";

function ProductBox(props) {
	return (
		<div className="py-1 text-center" style={{ cursor: "pointer" }}>
			<img className="image__product--list" src={props.src} alt={props.title} />
			<p classtitle="lead text-center">{props.title}</p>
		</div>
	);
}

export default class Product extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			products: [],
			error: false,
			requesting: false,
			loading: false,
		};
		this.onClick = this.onClick.bind(this);
	}

	async _getUserContract() {
		if (this.state.requesting) {
			return;
		}
		this.setState({ ...this, loading: true, requesting: true });
		await getUserContract()
			.then((products) => {
				this.setState({ ...this, loading: false });
				this.setState({ ...this, products, success: true });
			})
			.catch((error) => {
				this.setState({ ...this, error: true, loading: false });
			});
	}

	onClick(link) {
		console.log(link);
		window.open(link, "__blank");
	}

	componentDidMount() {
		this._getUserContract();
	}
	render() {
		return (
			<Page title="Produtos">
				<div className="row">
					<p className={this.state.loading ? "text-center" : "d-none"}>
						carregando ...
					</p>
					{this.state.products.map((product) => {
						return (
							<Link
								to={`/product/view/${product.id}`}
								data-link={product.resource}
								className="col-md-4 col-xs-12 mb-3"
								key={product.id}
							>
								<ProductBox src={product.feature_img} title={product.name} />
							</Link>
						);
					})}
				</div>
			</Page>
		);
	}
}
