import React from 'react';
import ReactDOM from 'react-dom/client';
import { ToastContainer } from 'react-toastify';

import 'react-toastify/dist/ReactToastify.css';
import './assets/scss/bootstrap/bootstrap.scss';
import './index.css'

import BackOffice from './views/backoffice';
import Dashboard from './views/dashboard';
import Public from './views/public';

import reportWebVitals from './reportWebVitals';

const root = ReactDOM.createRoot(document.getElementById('root'));

const key = window.localStorage.getItem(process.env.REACT_APP_SITE_STORAGE_KEY_API_KEY)
const role = window.localStorage.getItem(process.env.REACT_APP_SITE_STORAGE_KEY_USER_ROLE)

if (key) {
  root.render(
    <React.StrictMode>
      {
        (role && role.split("|")[0] === key) ? <BackOffice /> : <Dashboard />
      }
    </React.StrictMode>
  );

} else {
  root.render(
    <React.StrictMode>
      <Public />
      <ToastContainer />
    </React.StrictMode>
  );
}

reportWebVitals();
