/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import { Table, Button, FormControl } from "react-bootstrap";
import { getHistory } from "../../../../axios";

const LogHistory = () => {
	const [history, setHistory] = React.useState([]);
	const [params, setParams] = React.useState({});

	async function getLogHistory() {
		setHistory([]);
		await getHistory(params).then((data) => setHistory(data));
	}

	function onChange(e) {
		const newParams = { ...params };
		newParams[e.target.name] = e.target.value;
		setParams(newParams);
	}

	React.useEffect(() => {
		setTimeout(getLogHistory, 200);
	}, []);

	return (
		<div className="container">
			<div className="d-flex align-items-center gap-5">
				<div className="d-flex justify-content-start mb-2 text-white">
					<div className="form-group">
						<div className="form-label">Data inicial</div>
						<FormControl
							name="start_date"
							className="select__adm input__date"
							type="date"
							onChange={onChange}
						/>
					</div>
					<div className="mx-3"></div>
					<div className="form-group">
						<div className="form-label text-white">Data Final</div>
						<FormControl
							name="end_date"
							className="select__adm input__date"
							type="date"
							onChange={onChange}
						/>
					</div>
				</div>
				<Button onClick={getLogHistory} className="filter__button">
					Filtrar
				</Button>
			</div>

			<div className="col-x">
				<Table bordered responsive hover size="sm" className="list-users-table">
					<thead>
						<tr>
							<th className="text-center">Ação</th>
							<th className="text-center">Data/Hora</th>
							<th className="text-center">Descrição</th>
						</tr>
					</thead>
					<tbody>
						{history.map((history, index) => {
							return (
								<tr key={index}>
									<td className="text-center">{history.action}</td>
									<td className="text-center">{history.created_date}</td>
									<td className="text-center">{history.description}</td>
								</tr>
							);
						})}
					</tbody>
				</Table>
			</div>
		</div>
	);
};

export default LogHistory;
