import styled from "styled-components";

export const ContainerCheckList = styled.div`
	display: flex;
	width: 100%;
	flex-direction: column;
	padding-bottom: 26px;
`;

export const CheckItem = styled.div`
	background: ${(props) => (props.isActual ? "#D2FF00" : "#282828")};
	border-radius: 27px;
	height: 57px;
	padding: 14px 0 13px 23px;
	gap: 21px;
	display: flex;
	align-items: center;
	width: 100%;

	& > button {
		border-radius: 4px;
		border: 1px solid #d2ff00;
		border-color: ${(props) => (!props.isActual ? "#FFFFFF" : "#282828")};
		width: 20px;
		height: 20px;
		flex-shrink: 0;
		background-color: transparent;

		& > img {
			position: relative;
			top: -4px;
			left: -3px;
		}
	}

	& > p {
		color: #fff;
		font-size: 20px;
		font-family: Poppins;
		font-style: normal;
		font-weight: 400;
		color: ${(props) => (!props.isActual ? "#FFFFFF" : "#282828")};
		line-height: normal;
	}
	@media screen and (max-width: 768px) {
		& > p {
			font-size: 14px !important;
		}
	}
`;
