import React from "react";
import styles from "./styles.module.css";

export const FormGroup = ({ errors, register, registerName, placeholder }) => {
	const errorClass = !!errors ? "input__error" : "";
	return (
		<div>
			<input
				type="text"
				placeholder={placeholder}
				{...register(registerName)}
				className={`${styles.input__form} ${styles[errorClass]}`}
			/>
			{!!errors && <p className={styles.error__message}> {errors}</p>}
		</div>
	);
};
