import React from "react";
import styled from "styled-components";

import { getUserContract } from "../../../../axios";

const Footer = styled.div`
	background-color: #333333;
	height: 100px;
	position: relative;
	margin-top: -40px;
	max-width: 100%;
`;

export default class ViewProduct extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			product: {},
			loading: false,
			width: 0,
			height: 0,
		};
	}
	getProductDetails() {
		this.setState({ ...this, loading: true });
		getUserContract().then((result) => {
			const itemId = window.location.href.split("/product/view/")[1];
			const product = result.filter((item) => item.id === itemId);
			this.setState({ ...this, product: product[0], loading: false });
			setTimeout(() => {
				this.adaptIframeScreen();
			}, 100);
		});
	}

	adaptIframeScreen() {
		const { clientHeight: height, clientWidth: width } =
			document.getElementById("inner-page__container");
		this.setState({ ...this, height: height * 0.9, width: width });
	}
	componentDidMount() {
		this.getProductDetails();
	}
	render() {
		return (
			<div className="container">
				<div className="row">
					<div className="col-12">
						<p className={this.state.loading ? "text-center" : "d-none"}>
							carregando ...
						</p>
						<div className="d-flex justify-content-center">
							<iframe
								style={{ borderRadius: 10 }}
								height={this.state.height}
								width={this.state.width}
								src={this.state.product.resource}
								frameborder="0"
							/>
						</div>
						<Footer />
					</div>
				</div>
			</div>
		);
	}
}
