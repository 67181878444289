import React from "react";
import { Badge, Button, Nav, Form, Modal, Collapse } from "react-bootstrap";
import { toast } from "react-toastify";
import { Link } from "react-router-dom";
import {
	getUser,
	resetUserPassword,
	getUserPaymentHistory,
	updateUser,
	updateUserContract,
	deleteUser,
	getConfig,
} from "../../../axios";
import ReactInputMask from "react-input-mask";
import PasswordInput from "../../../components/form/PasswordInput";

export default class EditUser extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			id: "",
			page: "",
			data: {},
			loading: false,
			showModalDeleteUser: false,
			loadingDelete: false,
		};
		this.onClick = this.onClick.bind(this);
	}
	onClick(e) {
		const { id: page } = e.target;
		this.setState({ ...this, page });
	}
	_getUser(id) {
		this.setState({ ...this, loading: true });
		getUser(id)
			.then((data) => {
				this.setState({ ...this, data, loading: false });
				if (window.location.search.includes("delete-user")) {
					this.setState({ ...this, showModalDeleteUser: true });
				} else if (window.location.search.includes("change-password")) {
					setTimeout(() => {
						document.getElementById("change-password").click();
					}, 300);
				}
			})
			.catch((err) => {
				this.setState({ ...this, loading: false });
			});
	}
	componentDidMount() {
		const id = window.location.pathname.split("/")[2];
		this.setState({ ...this, id, page: "client-data" });
		this._getUser(id);
	}

	render() {
		if (this.state.loading) {
			return (
				<div className="">
					<p className="text-center">
						<i className="fa-2x fa-spin fa-solid fa-spinner"></i>
					</p>
				</div>
			);
		}
		return (
			<div className="row" style={{ background: "rgb(64, 64, 64)" }}>
				<Link className="py-3" to="/">
					<i className="fa-2x fa-solid fa-arrow-left"></i>
				</Link>
				<Nav
					variant="pills"
					className="d-flex justify-content-between"
					defaultActiveKey="home"
				>
					<Nav.Item>
						<Nav.Link
							active={this.state.page === "client-data"}
							onClick={this.onClick}
							id="client-data"
							className="px-5 select__adm"
							eventKey="client-data"
						>
							Dados do Cliente
						</Nav.Link>
					</Nav.Item>
					<Nav.Item>
						<Nav.Link
							active={this.state.page === "change-password"}
							onClick={this.onClick}
							className="px-5 select__adm"
							id="change-password"
							eventKey="change-password"
						>
							Alterar Senha
						</Nav.Link>
					</Nav.Item>
					<Nav.Item>
						<Nav.Link
							active={this.state.page === "payment-history"}
							onClick={this.onClick}
							className="px-5 select__adm"
							id="payment-history"
							eventKey="payment-history"
						>
							Histórico de Pagamentos
						</Nav.Link>
					</Nav.Item>
				</Nav>
				<div className="py-4">
					{this.state.page === "client-data" ? (
						<ClientData loading={this.state.loading} data={this.state.data} />
					) : (
						<span />
					)}
					{this.state.page === "change-password" ? (
						<ChangePasssoword
							loading={this.state.loading}
							data={this.state.data}
						/>
					) : (
						<span />
					)}
					{this.state.page === "payment-history" ? (
						<ClientPaymentHistory
							loading={this.state.loading}
							data={this.state.data}
						/>
					) : (
						<span />
					)}
				</div>
				<Modal show={this.state.showModalDeleteUser}>
					<Modal.Header>Deletar o usuário?</Modal.Header>
					<Modal.Body>
						<p id="p-delete-user" className="lead text-center">
							Clique no botão abaixo para deletar o usuário
						</p>
						<p
							onClick={
								this.state.loadingDelete
									? null
									: () => {
											this.setState({ ...this, loadingDelete: true });
											deleteUser(this.state.data.id)
												.then(() => {
													document.getElementById("p-delete-user").textContent =
														"Usuário deletado!";
													setTimeout(() => {
														window.location.href = "/";
													}, 2500);
												})
												.catch((error) => {});
									  }
							}
							className="lead text-center"
							style={{ cursor: this.state.loadingDelete ? "wait" : "pointer" }}
						>
							<i className="fa-solid fa-trash-can fa-2x text-danger"></i>
						</p>
					</Modal.Body>
					<Modal.Footer>
						<Button
							disabled={this.state.loadingDelete}
							onClick={() =>
								this.setState({ ...this, showModalDeleteUser: false })
							}
						>
							Cancelar
						</Button>
					</Modal.Footer>
				</Modal>
			</div>
		);
	}
}

function ClientData(props) {
	const [show, setShow] = React.useState(false);
	const [showStatusModal, setShowStatusModal] = React.useState(false);
	const [data, setData] = React.useState({ products: { data: [] } });
	const [loading, setLoading] = React.useState(false);
	const [products, setProducts] = React.useState([]);
	const [requiredReason, setRequiredReason] = React.useState(false);

	function onChange(e) {
		let { name: key, value } = e.target;
		const newData = {};
		if (key === "is_active") {
			value = value === "on" ? "" : "on";
		} else if (key === "free_access") {
			value = value === "on" ? "" : "on";
		}
		setRequiredReason(true);
		newData[key] = value;
		setData({ ...data, ...newData });
		console.log(e.target);
	}

	function changeProductStatus(e) {
		const btt_c = document.getElementsByClassName("buttonConfirmation");
		const id = btt_c.id.substring(0, btt_c.id.length - 5);

		const button = document.getElementById(id);
		const checked = button.checked;

		const contractData = {
			contract_status: "inactive",
		};

		updateUserContract(id, contractData)
			.then((msg) => {
				for (let x = 0; x < data.products.data.length; x++) {
					if (data.products.data[x].id === id) {
						data.products.data[x].status = !checked;
						setData({ ...data });
						break;
					}
				}
				setData({ ...data });
				setRequiredReason(true);
				setShowStatusModal(false);
			})
			.catch((error) => {
				console.log(error);
			});
	}
	function openConfirmationModal(e) {
		const { id } = e.target;
		const btt_c = document.getElementsByClassName("buttonConfirmation");
		btt_c.id = id + "-conf";
		setShowStatusModal(true);
	}

	function update() {
		try {
			document.getElementById("alert-not-reason").classList.add("d-none");
		} catch (error) {}
		if (!data.phone) {
			return;
		}
		if (requiredReason) {
			if (!data.open_product_reason) {
				try {
					document
						.getElementById("alert-not-reason")
						.classList.remove("d-none");
					document.getElementById("opened-product-reason").focus();
				} catch (error) {}
				return;
			}
		}

		setLoading(true);

		data["cpf"] = data.cpf.replace(/\D+/g, "");
		data["phone"] = data.phone.replace(/\D+/g, "");

		if (data.cpf.toString().length < 11) {
			toast.error("O CPF é inálido!", {
				toastId: "validate-cpf-toast-error",
			});
			setLoading(false);
			return;
		}
		if (data.phone.length < 10) {
			toast.error("O Telefone é inálido!", {
				toastId: "validate-cpf-phone-number-error",
			});
			setLoading(false);
			return;
		}

		updateUser(props.data.id, data)
			.then((data) => {
				setShow(true);
				setTimeout(() => {
					window.location.reload();
				}, 2000);
			})
			.catch((error) => {
				setLoading(false);
				if (error.response) {
					const { status } = error.response;
					if (status === 400) {
						let errorMessage = "";
						if (error.response.data.email) {
							errorMessage = error.response.data.email[0];
						} else {
							errorMessage = error.response.data.error[0];
						}
						toast.error(errorMessage, {
							toastId: "update-user-request-error",
						});
					}
				}
			});
	}

	function onPressEnter(e) {
		if (e.key === "Enter") {
			update();
		}
	}

	function getProducts() {
		getConfig().then((products) => {
			setProducts(products.results);
		});
	}

	function onChangeAddProduct(e) {
		const { value } = e.target;
		const newData = data;
		newData["open_product"] = value;
		setData(newData);
		if (value.length > 10) {
			try {
				document.getElementById("opened-product-reason").focus();
			} catch (error) {}
		}
	}
	function onChangeAddProductReason(e) {
		const { value } = e.target;
		const newData = data;
		newData["open_product_reason"] = value;
		setData(newData);
	}

	React.useEffect(() => {
		let allow_access = "";
		if (props.data.contract) {
			allow_access = props.data.contract.status === "active" ? "on" : "";
		}
		setTimeout(() => {
			if (!data.products.data.length) {
				document
					.getElementById("allow-freedom-test")
					.classList.remove("d-none");
			}
			getProducts();
		}, 150);
		setData({ ...props.data, allow_access });
	}, []);

	return (
		<div>
			<Modal show={showStatusModal}>
				<Modal.Header>
					Desativar o acesso do cliente a esse plano?
					<button
						className="buttonConfirmation"
						id=""
						onClick={changeProductStatus}
					>
						Sim
					</button>
					<button
						className="buttonDeny"
						onClick={() => {
							setShowStatusModal(false);
						}}
					>
						Não
					</button>
				</Modal.Header>
			</Modal>
			<div className="backoffice-edit-user-first-fields">
				<div className="form-group">
					<label htmlFor="#">
						<p className="text-white">Nome Completo</p>
						<Form.Control
							name="name"
							className="select__adm"
							onKeyDown={onPressEnter}
							onChange={onChange}
							disabled={loading}
							value={data.name}
						/>
					</label>
				</div>
				<div className="form-group">
					<label htmlFor="#">
						<p className="text-white">E-mail</p>
						<Form.Control
							name="email"
							className="select__adm"
							onChange={onChange}
							onKeyDown={onPressEnter}
							disabled={loading}
							value={data.email}
						/>
					</label>
				</div>
				<div className="form-group">
					<label htmlFor="#">
						<p className="text-white">CPF</p>
						<ReactInputMask
							className="form-control select__adm"
							mask="999.999.999-99"
							name="cpf"
							onChange={onChange}
							onKeyDown={onPressEnter}
							disabled={loading}
							value={data.cpf}
						/>
					</label>
				</div>
			</div>
			<div className="backoffice-edit-user-second-fields">
				<div>
					<div className="form-group">
						<label htmlFor="#">
							<p className="text-white">Telefone</p>
							<ReactInputMask
								name="phone"
								className={`form-control select__adm  ${
									!data.phone ? "is-invalid" : ""
								}`}
								mask="(99) 99999-9999"
								onChange={onChange}
								onKeyDown={onPressEnter}
								disabled={loading}
								value={data.phone}
							/>
						</label>
					</div>
				</div>
				<div
					className="backoffice-edit-user-switchs-area d-none"
					id="allow-freedom-test"
				>
					<div className="d-flex justify-content-around">
						<div className="form-group">
							<div className="form-label text-white">Liberação manual</div>
							<select
								disabled={!products.length || loading}
								onChange={onChangeAddProduct}
								className="form-control select__adm"
							>
								<option value="">Selecione</option>
								{products.map((product, index) => (
									<option key={product.id + "" + index} value={product.id}>
										{product.product_name}
									</option>
								))}
							</select>
						</div>
						<div style={{ width: "40px" }} />
						<div className="form-group">
							<div className="form-label text-white">
								Status de Acesso da Conta
							</div>
							<Form.Check
								name="is_active"
								disabled={loading}
								className="mr-3"
								type="switch"
								id="is_active"
								onClick={onChange}
								value={data.is_active}
								checked={data.is_active ? "true" : "false"}
							/>
						</div>
					</div>
				</div>
			</div>
			<div className="py-2">
				<div className="formg">
					<div className="form-label text-white">Descrição das alterações</div>
				</div>
				<textarea
					id="opened-product-reason"
					className="form-control select__adm"
					onChange={onChangeAddProductReason}
					disabled={!products.length || loading}
					cols="30"
					rows="3"
				/>
				<span id="alert-not-reason" className="text-danger d-none">
					Por favor insira uma descrição sobre as alterações realizadas!
				</span>
			</div>
			<ul className="list-group">
				{data.products.data.map((product) => {
					const options = {
						year: "numeric",
						month: "short",
						day: "numeric",
						hour: "numeric",
						minute: "numeric",
					};
					const currentDate = new Date().toISOString("pt-BR", options);
					if (product.status === true) {
						return (
							<li
								key={`client-product-${product.id}`}
								className="list-group-item d-flex justify-content-between select__adm my-3"
							>
								{product.name}
								<div className="d-flex justify-content-between gap-2 ">
									<div className="form-label">Status de Acesso</div>
									<Form.Check
										name="allow_access"
										checked={
											product.end_date > currentDate && product.status
												? "true"
												: "false"
										}
										onClick={openConfirmationModal}
										disabled={loading}
										className="mr-3"
										type="switch"
										id={product.id}
										onChange={() => {}}
									/>
								</div>
							</li>
						);
					}
					return <></>;
				})}
			</ul>
			<button
				onClick={update}
				disabled={loading}
				className="btn btn-primary block w-100"
			>
				{loading ? (
					<React.Fragment>
						Atualizando ... <i className="fa-spin fa-solid fa-spinner"></i>
					</React.Fragment>
				) : (
					<React.Fragment>Atualizar</React.Fragment>
				)}
			</button>
			<Modal show={show}>
				<Modal.Header>Os dados do cliente foram atualizados!</Modal.Header>
			</Modal>
		</div>
	);
}

function ClientPaymentHistory(props) {
	const [data, setData] = React.useState([]);
	const [openedIndex, toggleOpenedIndex] = React.useState(-1);
	const [loading, setLoading] = React.useState(false);

	function load() {
		setData([]);
		setLoading(true);
		getUserPaymentHistory(props.data.id)
			.then((data) => {
				setData(data);
				setLoading(false);
			})
			.catch((err) => setLoading(false));
	}

	return (
		<div className="">
			<div className="d-flex justify-content-center">
				<button
					disabled={loading}
					className="btn btn-primary my-4"
					onClick={load}
				>
					<i
						className={`${loading ? "fa-spin" : ""} fa-solid fa-arrows-rotate`}
					></i>
				</button>
			</div>
			<ul className="list-group">
				{data.length === 0 ? (
					<li className="list-group-item select__adm">Não há histórico</li>
				) : (
					<span></span>
				)}
				{data.map((history, index) => {
					const options = {
						weekday: "long",
						year: "numeric",
						month: "long",
						day: "numeric",
						hour: "numeric",
						minute: "numeric",
					};
					const createdDate = new Date(history.datetime).toLocaleDateString(
						"pt-BR",
						options
					);
					const key = `user-${props.data.id}-payment-history-${index}`;
					return (
						<li
							key={key}
							style={{ cursor: "pointer" }}
							title="Exibir detalhes"
							onClick={() => {
								if (openedIndex === index) {
									toggleOpenedIndex(-1);
								} else {
									toggleOpenedIndex(index);
								}
							}}
							className="list-group-item select__adm"
						>
							<p className="text-center">
								<Badge bg="primary" className="text-uppercase">
									{history.tag}
								</Badge>{" "}
								{history.title} - {createdDate}
							</p>

							<Collapse in={openedIndex === index}>
								<div className="bg-lighg">
									<p className="lead">{history.message}</p>
								</div>
							</Collapse>
						</li>
					);
				})}
			</ul>
		</div>
	);
}

function ChangePasssoword(props) {
	const [show, setModal] = React.useState(false);
	const [password, setPassword] = React.useState("");
	const [comfirmPassword, setComfirmPassword] = React.useState("");

	const [loading, setLoading] = React.useState(false);

	function save() {
		setLoading(true);
		resetUserPassword(props.data.id, password)
			.then((data) => {
				setModal(true);
				setTimeout(() => {
					window.location.reload();
				}, 2000);
			})
			.catch((error) => {});
	}

	return (
		<div className="">
			<div className="form-group">
				<div className="form-label text-white">Nova Senha</div>
				<PasswordInput
					type="password"
					className="select__adm"
					autoFocus
					color="text-white"
					disabled={loading}
					onChange={(e) => setPassword(e.target.value)}
					value={password}
				/>
			</div>
			<div className="form-group">
				<div className="form-label text-white">Confirmar nova Senha</div>
				<PasswordInput
					type="password"
					className="select__adm"
					color="text-white"
					disabled={loading}
					onChange={(e) => setComfirmPassword(e.target.value)}
					value={comfirmPassword}
				/>
			</div>
			<button onClick={save} disabled={loading} className="btn btn-primary">
				Atualizar senha
			</button>
			<Modal show={show}>
				<Modal.Header>A senha do usuário foi atualizada!</Modal.Header>
			</Modal>
		</div>
	);
}
