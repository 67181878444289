/* eslint-disable no-useless-escape */
import React from "react";
import { Table, FormControl } from "react-bootstrap";
import { Link } from "react-router-dom";
import { listUsers } from "../../../axios";
import Pagination from "react-bootstrap/Pagination";
import "./styles.css";

export default class ListUsers extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			data: { data: [], pages: [] },
			loading: false,
			query: {
				end_count: 10,
				page: 1,
			},
		};
		this._onChangeQueryParams = this._onChangeQueryParams.bind(this);
		this._getUsers = this._getUsers.bind(this);
	}
	_filterQuery() {
		let query = this.state.query;

		if (this.state.query.cpf) {
			query.cpf = this.state.query.cpf.split(".").join("").trim();
		} else if (
			this.state.query.name &&
			this.state.query.name.match(/(\.|\-)/g)
		) {
			query.cpf = this.state.query.name.replaceAll(/(\.|\-)/g, "").trim();
			delete query.name;
		} else if (query.name && query.name.split(" ").length === 1) {
			query.email = query.name;
			delete query.name;
		} else if (this.state.query.email) {
			query.email = this.state.query.email.trim();
		}
		return query;
	}
	_getUsers() {
		this.setState({ ...this, loading: true });
		listUsers(this._filterQuery())
			.then((response) => {
				window.scrollTo({ top: 0 });
				console.log(response);
				setTimeout(() => {
					let pages = [];
					if (response.pages > 7) {
						for (let x = 0; x < 6; x++) {
							pages.push(x);
						}
						pages.push(response.pages);
					} else {
						for (let x = 0; x < response.pages; x++) {
							pages.push(x);
						}
					}
					response["pages"] = pages;
					this.setState({ ...this, data: response, loading: false });
				}, 200);
			})
			.catch((error) => {
				window.scrollTo({ top: 0 });
				console.log(error);

				this.setState({ ...this, loading: false });
			});
	}

	_onChangeQueryParams(e) {
		let { name: key, value } = e.target;
		const { query } = this.state;
		delete query["name"];
		delete query["email"];
		delete query["cpf"];

		if (key === "page") {
			value = e.target.dataset.page;
		} else if (key === "name_cpf") {
			if (isNaN(value)) {
				delete query["cpf"];
				delete query["email"];
				key = "name";
			} else {
				delete query["name"];
				delete query["email"];
				key = "cpf";
			}
			if (value.includes("@")) {
				delete query["name"];
				delete query["cpf"];

				key = "email";
			}
			query[key] = value;
		}
		query[key] = value;

		this.setState({ ...this, query });

		if (key === "page") {
			setTimeout(this._getUsers, 200);
		}
	}
	_onKeyPressed(e) {
		if (e.key === "Enter") {
			this._getUsers();
		}
	}

	_maskCPF(cpf) {
		if (cpf && cpf?.length > 0) {
			return cpf.replace(/(\d{3})?(\d{3})?(\d{3})?(\d{2})/, "$1.$2.$3-$4");
		}
		return <span className="text-danger">???</span>;
	}

	componentDidMount() {
		this._getUsers();
	}
	render() {
		return (
			<div className="row footer-filter">
				<div className="col-12">
					{this.state.loading ? (
						<div className="py-4">
							<p className="text-center">
								<i className="fa-2x fa-spin fa-solid fa-spinner"></i>
							</p>
						</div>
					) : (
						<Table
							bordered
							responsive
							hover
							size="sm"
							className="list-users-table"
						>
							<thead>
								<tr className="backoffice-table-row ">
									<th className="text-center">Nome</th>
									<th className="text-center">CPF</th>
									<th className="text-center">Dados de Cadastro</th>
									<th className="text-center">Vencimento</th>
									<th className="text-center">E-mail</th>
									<th className="text-center">Status</th>
									<th className="text-center">Ações</th>
								</tr>
							</thead>
							<tbody>
								{this.state.data.data.map((item, index) => {
									const options = {
										year: "numeric",
										month: "long",
										day: "numeric",
									};
									const cpf = this._maskCPF(item.cpf);
									const createdDate = new Date(
										item.created_at
									).toLocaleDateString("pt-BR", options);
									let endDate = "";
									let status = undefined;
									if (item.products) {
										status = item.products.status;
										if (item.products.data) {
											endDate = new Date(
												item.products.data[0]?.end_date
											).toLocaleDateString("pt-BR", options);
										}
									}
									return (
										<tr key={`cliet-list-(${item.id}).${index}`}>
											<td className="backoffice-table-names">{item.name}</td>
											<td className="text-center">{cpf}</td>
											<td className="text-center">
												<small>
													{createdDate || (
														<span className="text-danger">???</span>
													)}
												</small>
											</td>
											<td className="text-center">
												<small>
													{endDate !== "Invalid Date" ? (
														endDate
													) : (
														<span className="text-danger">???</span>
													)}
												</small>
											</td>
											<td>{item.email}</td>
											<td className="text-center">
												{status ? (
													<span
														className={`text-${status ? "info" : "danger"}`}
													>
														{status ? "✅" : "❌"}
													</span>
												) : (
													"✖️"
												)}
											</td>
											<td>
												<div className="actions-icons">
													<Link to={`/user/${item.id}/`}>
														<i className="fa-regular fa-eye"></i>
													</Link>
													<Link to={`/user/${item.id}/?action=change-password`}>
														<i className="fa-solid fa-key"></i>
													</Link>
													<Link
														className="text-danger"
														to={`/user/${item.id}/?action=delete-user`}
													>
														<i className="fa-solid fa-trash-can"></i>
													</Link>
												</div>
											</td>
										</tr>
									);
								})}
							</tbody>
						</Table>
					)}
				</div>
				<div className="col-12 d-none d-sm-block">
					<div className="users-footer-filters">
						<div className="backoffice-pages">
							<p className="lead lr-3">Exibindo</p>
							<select
								name="end_count"
								onChange={this._onChangeQueryParams}
								className="form-control mr-3 select__adm"
							>
								{[1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((index) => (
									<option
										value={index * 5}
										selected={this.state.query.end_count === index * 5}
										key={`select-items-result-${index}`}
									>
										{index * 5} Resultados
									</option>
								))}
							</select>
							<i className="backoffice fa-solid fa-angle-down"></i>
						</div>
						<div className="backoffice-search-area">
							<div className="d-flex background-dark">
								<FormControl
									placeholder="Pesquise pelo nome, email ou CPF"
									name="name_cpf"
									onKeyDown={this._onKeyPressed.bind(this)}
									value={this.state.query.name_cpf}
									onChange={this._onChangeQueryParams}
									className="form-control select__adm"
								/>
								<button className="btn" onClick={this._getUsers}>
									<i
										className="fa-solid fa-magnifying-glass"
										style={{ color: "white" }}
									></i>
								</button>
							</div>
						</div>
						<div className="backoffice-paginator-area">
							<Pagination>
								<Pagination.Item
									key="home-paginator-next"
									className="background-dark"
								>
									<i className="fa-solid fa-chevron-left"></i>
								</Pagination.Item>
								{this.state.data.pages.map((page, index) => (
									<Pagination.Item
										className={
											this.state.query.page === page + 1
												? "active background-dark"
												: "background-gray"
										}
										data-page={page + 1}
										name="page"
										value={page + 1}
										onClick={this._onChangeQueryParams}
										key={`home-page-links-${index}`}
									>
										{page + 1}
									</Pagination.Item>
								))}

								<Pagination.Item
									key="home-paginator-prev"
									className="background-dark"
								>
									<i className="fa-solid fa-chevron-right"></i>
								</Pagination.Item>
							</Pagination>
						</div>
					</div>
				</div>
			</div>
		);
	}
}
