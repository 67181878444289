import React from "react";
import styled from "styled-components";

const MainContent = styled.div`
	min-height: 100vh;
`;
export default (props) => (
	<MainContent
		className="container-fluid bg-secondary"
		style={{ padding: 0, margin: 0 }}
	>
		{props.children}
	</MainContent>
);
