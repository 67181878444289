import React from "react";
import { FormControl } from "react-bootstrap";
import { v4 as uuid } from "uuid";
import { BiKey } from "react-icons/bi";

const id = uuid();

// eslint-disable-next-line import/no-anonymous-default-export
export default (props) => {
	const [type, setType] = React.useState("password");

	function onChangeType() {
		let newType = "";
		const el = document.getElementById(id);
		if (el.type === "text") {
			newType = "password";
		} else if (el.type === "password") {
			newType = "text";
		}
		setType(newType);
	}
	if (!props.withIcon) {
		return (
			<div
				style={{
					display: "flex",
				}}
			>
				<FormControl
					type={type}
					required={props.required}
					value={props.value}
					className={props?.className}
					disabled={props.disabled}
					onChange={props.onChange}
					name={props.name}
					id={id}
				/>

				<div
					onClick={onChangeType}
					style={{
						display: "flex",
						flexDirection: "row",
						alignItems: "center",
						marginLeft: -30,
						cursor: "pointer",
					}}
				>
					{type === "password" ? (
						<i className={`fa-solid fa-eye ${props.color}`}></i>
					) : (
						<i className={`fa-solid fa-eye-slash ${props.color}`}></i>
					)}
				</div>
			</div>
		);
	}

	return (
		<div
			style={{
				display: "flex",
				height: "41px",
			}}
		>
			<FormControl
				type={type}
				required={props.required}
				value={props.value}
				disabled={props.disabled}
				onChange={props.onChange}
				name={props.name}
				className="input__form__password"
				id={id}
			/>
			<div className="input__container-form">
				<BiKey style={{ transform: "rotate(45deg)" }} className="svg__key" />
			</div>
			<div
				onClick={onChangeType}
				style={{
					display: "flex",
					flexDirection: "row",
					alignItems: "center",
					marginLeft: -30,
					cursor: "pointer",
				}}
			>
				{type === "password" ? (
					<i className={`fa-solid fa-eye ${props.color}`}></i>
				) : (
					<i className={`fa-solid fa-eye-slash ${props.color}`}></i>
				)}
			</div>
		</div>
	);
};
