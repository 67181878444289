import React from "react";

const DetailsFormPart = ({ setDetails, details, index }) => {
	return (
		<div className="d-flex gap-3 pt-2">
			<div>
				<label for="period-1" className="form-label text-white">
					Período
				</label>
				<input
					id={`period-${index}`}
					className="form-control select__adm"
					value={details?.[index]?.period}
					onChange={(e) =>
						setDetails((old) => {
							const newState = [...old];
							newState[index].period = e.target.value;
							return newState;
						})
					}
				/>
			</div>
			<div>
				<label for="value-1" className="form-label text-white">
					Unidades
				</label>
				<input
					id={`value-${index}`}
					className="form-control select__adm"
					type="number"
					step="0.01"
					value={details?.[index]?.value}
					onChange={(e) =>
						setDetails((old) => {
							const newState = [...old];
							newState[index].value = e.target.value;
							console.log(newState, "news", index);
							return newState;
						})
					}
				/>
			</div>
		</div>
	);
};

export default DetailsFormPart;
